import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import RelationAppointments from "@/components/Relations/RelationDetailAppointments.vue";
import RelationDetails from "@/components/Relations/RelationDetails.vue";
import RelationDetailsSkeleton from "@/components/Relations/RelationDetailsSkeleton.vue";
import RelationTodos from "@/components/Relations/RelationDetailTodos.vue";
import RelationReportMenu from "@/components/Relations/RelationReportMenu.vue";
import { goBack } from "@/Helpers/NavigationHelpers";
import { GetRelationContacts, getRelationDetails } from "@/Webservice/Relation";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Relations = namespace("Relations/");
let RelationDetailsView = class RelationDetailsView extends Vue {
    selectedRelation = null;
    tab = 0;
    async loadRelation() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            goBack();
            return;
        }
        let relation = await getRelationDetails(id);
        if (relation) {
            this.selectedRelation = relation;
            this.selectedRelation.Contacts = await GetRelationContacts(relation.Id);
        }
        else {
            console.error('Relation not found');
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadRelation();
    }
};
__decorate([
    Watch('$route', { immediate: true, deep: true })
], RelationDetailsView.prototype, "onUrlChange", null);
RelationDetailsView = __decorate([
    Component({
        components: {
            RelationDetails,
            RelationAppointments,
            RelationTodos,
            AppBar,
            RelationDetailsSkeleton,
            RelationReportMenu
        },
    })
], RelationDetailsView);
export default RelationDetailsView;
