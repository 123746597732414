import { __decorate } from "tslib";
import { Prop, Component, Vue } from 'vue-property-decorator';
let CRMDataField = class CRMDataField extends Vue {
    title;
    value;
    icon;
    get hasClickEvent() {
        return !!this.$listeners['click'];
    }
};
__decorate([
    Prop({ type: String, default: "" })
], CRMDataField.prototype, "title", void 0);
__decorate([
    Prop({ type: String, default: "" })
], CRMDataField.prototype, "value", void 0);
__decorate([
    Prop({ type: String, default: "" })
], CRMDataField.prototype, "icon", void 0);
CRMDataField = __decorate([
    Component
], CRMDataField);
export default CRMDataField;
