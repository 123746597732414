import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import TodoEditAppBar from "@/components/Todos/TodoEditAppBar.vue";
import TodoEditForm from "@/components/Todos/TodoEditForm.vue";
import TodoEditSkeleton from "@/components/Todos/TodoEditSkeleton.vue";
import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Todos = namespace("Todos/");
let AppointmentEditView = class AppointmentEditView extends Vue {
    todo;
    saving;
    error;
    finished;
    appBarTitle;
    updateTodo;
    todoEdit;
    async saveTodo() {
        if (!this.todoEdit.validate()) {
            return;
        }
        this.$emit('saveTodo');
    }
    setFinished() {
        return this.finished;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentEditView.prototype, "todo", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditView.prototype, "saving", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditView.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditView.prototype, "finished", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], AppointmentEditView.prototype, "appBarTitle", void 0);
__decorate([
    Todos.Action
], AppointmentEditView.prototype, "updateTodo", void 0);
__decorate([
    Ref("todoEdit")
], AppointmentEditView.prototype, "todoEdit", void 0);
__decorate([
    Emit('setFinished')
], AppointmentEditView.prototype, "setFinished", null);
AppointmentEditView = __decorate([
    Component({
        components: {
            TodoEditForm,
            TodoEditAppBar,
            AppBar,
            TodoEditSkeleton
        },
    })
], AppointmentEditView);
export default AppointmentEditView;
