import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import ContactEdit from "@/components/Contacts/ContactEdit.vue";
import ContactEditAppBar from "@/components/Contacts/ContactEditAppBar.vue";
import ContactEditSkeleton from "@/components/Contacts/ContactEditSkeleton.vue";
import { Contact } from "@/types/Api/contact";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { createContact, saveContact, getContactDetails } from "@/Webservice/Contact";
import { getRelationDetails } from "@/Webservice/Relation";
import { goBack, openContact } from "@/Helpers/NavigationHelpers";
const NSContact = namespace("Contact/");
const NSRelations = namespace("Relations/");
let ContactEditView = class ContactEditView extends Vue {
    selectedContact = null;
    saving = false;
    error = false;
    finished = false;
    appBarTitle = "";
    async loadContact() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            this.appBarTitle = this.$t('new-contact').toString();
            // retrieve relation data
            let relationIdStr = this.$route.params['relationId'];
            let relationId = Number.parseInt(relationIdStr);
            let relation = await getRelationDetails(relationId);
            if (relation) {
                this.selectedContact = new Contact();
                this.selectedContact.Relation = relation;
                return;
            }
            else {
                console.error('Relation not found');
                goBack();
            }
        }
        this.appBarTitle = this.$t('edit-contact').toString();
        let contact = await getContactDetails(id);
        if (contact) {
            this.selectedContact = contact;
        }
        else {
            console.error('Contact not found');
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadContact();
    }
    contactEdit;
    async saveContact() {
        if (!this.selectedContact || !this.contactEdit.validate())
            return;
        this.saving = true;
        let updatedContact = undefined;
        let created = false;
        if (this.selectedContact.Id > 0) {
            updatedContact = await saveContact(this.selectedContact);
        }
        else {
            updatedContact = await createContact(this.selectedContact);
            created = true;
        }
        if (updatedContact?.Id > 0) {
            this.selectedContact = updatedContact;
            this.finished = true;
            if (created)
                openContact(this.selectedContact.Id, true);
            else
                goBack();
        }
        else {
            this.error = true;
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Watch('$route', { immediate: true, deep: true })
], ContactEditView.prototype, "onUrlChange", null);
__decorate([
    Ref('contactEdit')
], ContactEditView.prototype, "contactEdit", void 0);
ContactEditView = __decorate([
    Component({
        components: {
            ContactEdit,
            ContactEditAppBar,
            AppBar,
            ContactEditSkeleton
        },
    })
], ContactEditView);
export default ContactEditView;
