import { __decorate } from "tslib";
import TodoAvatar from "@/components/Todos/TodoAvatar.vue";
import { TodoState } from "@/types/Api/todo";
import { Component, Prop, Vue } from "vue-property-decorator";
let TodoListItem = class TodoListItem extends Vue {
    todo;
    showDivider;
    timeOptions = { hour: '2-digit', minute: '2-digit' };
    isAssigned() {
        return this.todo.State == TodoState.Assigned;
    }
    get combinedDetails() {
        let detail = "";
        if (this.todo.TodoType?.Description)
            detail += this.todo.TodoType?.Description;
        if (detail && this.todo.Relation) {
            detail += " - ";
            detail += this.todo.Relation.RecordTag;
        }
        return detail;
    }
    getState() {
        let state = "";
        if (this.todo.State == TodoState.Assigned)
            state = this.$t('todo-state-assigned').toString();
        else if (this.todo.State == TodoState.Done)
            state = this.$t('todo-state-done').toString();
        else if (this.todo.State == TodoState.Open)
            state = this.$t('todo-state-open').toString();
        else if (this.todo.State == TodoState.WaitingForResponse)
            state = this.$t('todo-state-waitingforresponse').toString();
        return state;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoListItem.prototype, "todo", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TodoListItem.prototype, "showDivider", void 0);
TodoListItem = __decorate([
    Component({
        components: {
            TodoAvatar
        },
    })
], TodoListItem);
export default TodoListItem;
