import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import TodoActionsMenu from "@/components/Todos/TodoActionsMenu.vue";
import TodoDetailsComponent from "@/components/Todos/TodoDetailsComponent.vue";
import TodoDetailsSkeleton from "@/components/Todos/TodoDetailsSkeleton.vue";
import { editTodo } from "@/Helpers/NavigationHelpers";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Todos = namespace("Todos/");
let TodoDetailsView = class TodoDetailsView extends Vue {
    todo;
    updateTodo;
    editTodo() {
        if (this.todo) {
            editTodo(this.todo.Id);
        }
    }
    onTodoChanged(todo) {
        this.updateTodo(todo);
        return todo;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoDetailsView.prototype, "todo", void 0);
__decorate([
    Todos.Action
], TodoDetailsView.prototype, "updateTodo", void 0);
__decorate([
    Emit('onTodoChanged')
], TodoDetailsView.prototype, "onTodoChanged", null);
TodoDetailsView = __decorate([
    Component({
        components: {
            TodoDetailsComponent,
            AppBar,
            TodoActionsMenu,
            TodoDetailsSkeleton,
            AppBarResponsiveButton
        },
    })
], TodoDetailsView);
export default TodoDetailsView;
