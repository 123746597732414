import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import AppointmentEditForm from "@/components/Appointments/AppointmentEditForm.vue";
import { Component, Ref, Vue, Prop, Emit } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AppointmentEditAppBar from "@/components/Appointments/AppointmentEditAppBar.vue";
import AppointmentEditSkeleton from "@/components/Appointments/AppointmentEditSkeleton.vue";
const Appointments = namespace("Appointments/");
let AppointmentEditView = class AppointmentEditView extends Vue {
    appointment;
    saving;
    error;
    finished;
    appBarTitle;
    appointmentEdit;
    async saveAppointment() {
        if (this.appointment) {
            if (!this.appointmentEdit.validate()) {
                return;
            }
            this.$emit('saveAppointment');
        }
    }
    setFinished() {
        return this.finished;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentEditView.prototype, "appointment", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditView.prototype, "saving", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditView.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditView.prototype, "finished", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], AppointmentEditView.prototype, "appBarTitle", void 0);
__decorate([
    Ref('appointmentEdit')
], AppointmentEditView.prototype, "appointmentEdit", void 0);
__decorate([
    Emit('setFinished')
], AppointmentEditView.prototype, "setFinished", null);
AppointmentEditView = __decorate([
    Component({
        components: {
            AppointmentEditForm,
            AppointmentEditAppBar,
            AppBar,
            AppointmentEditSkeleton
        },
    })
], AppointmentEditView);
export default AppointmentEditView;
