import { getInstance } from './auth';
import store from '../../store';
export const authGuard = (to, from, next) => {
    const authService = getInstance();
    const fn = async () => {
        // Unwatch loading
        unwatch && unwatch();
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            if (store.state.apiuserid) {
                await store.dispatch('fetchEmployeeId');
            }
            return next();
        }
        // Otherwise, log in
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };
    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }
    // Watch for the loading property to change before we check isAuthenticated
    const unwatch = authService.$watch('loading', (loading) => {
        if (loading === false) {
            return fn();
        }
    });
};
