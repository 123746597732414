import { VueAuth } from './VueAuth';
import { redirect_uri } from '../../../auth_config.json';
/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState) => {
    window.history.replaceState({}, document.title, window.location.pathname);
};
let instance;
/** Returns the current instance of the SDK */
export const getInstance = () => instance;
/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = redirect_uri ?? window.location.origin, ...options }) => {
    if (instance)
        return instance;
    // The 'instance' is simply a Vue object
    instance = new VueAuth();
    instance.init(onRedirectCallback, redirectUri, options);
    return instance;
};
// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options);
    }
};
