import axios from 'axios';
import store from '../store';
import { getInstance as getAuthInstance } from '../plugins/auth/auth';
import { audience, redirect_uri } from '../../auth_config.json';
async function addTokenToConfig(config) {
    if (getAuthInstance().isAuthenticated) {
        if (config.headers) {
            const token = await getAuthInstance().getTokenSilently({
                cacheMode: "on",
                authorizationParams: {
                    audience: audience,
                    redirect_uri: redirect_uri,
                }
            });
            config.headers['Authorization'] = 'Bearer ' + token;
            if (store.state?.apiuserid) {
                config.headers['x-apiuser-Id'] = store.state.apiuserid;
            }
        }
    }
    return config;
}
export default function setup() {
    axios.interceptors.request.use(function (config) {
        return addTokenToConfig(config);
    }, (error) => error);
    axios.interceptors.response.use((response) => response, async (error) => {
        const originalRequest = error.config;
        if (error.response?.data?.detail) {
            const responseData = {
                Error: error.response.data.detail,
                Message: error.response.data.detail,
                ErrorCode: error.response.data.status,
                Status: error.response.data.status,
                StackTrace: '',
                Details: ''
            };
            store.commit('setWebserviceError', responseData);
        }
        else if (error.response?.data) {
            const responseData = {
                Error: error.response.data,
                Message: error.response.data,
                ErrorCode: error.status,
                Status: error.status,
                StackTrace: '',
                Details: ''
            };
            store.commit('setWebserviceError', responseData);
        }
        else {
            store.commit('setWebserviceError', { Message: error.message, Error: error.message });
        }
        if (error.response)
            store.commit('setWebserviceStatusCode', error.response.status);
        return Promise.resolve(error);
    });
}
