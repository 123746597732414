import { format, roundToNearestMinutes } from 'date-fns';
const DEFAULT_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export function formatDateForJSON(date) {
    return format(date, DEFAULT_DATETIME_FORMAT);
}
export function parseDate(date) {
    return new Date(date);
}
export function roundToNearest(date) {
    return roundToNearestMinutes(date, { nearestTo: 5 });
}
