import { __decorate } from "tslib";
import { Component, Vue, Prop } from "vue-property-decorator";
import RelationListItem from "@/components/Relations/RelationListItem.vue";
import RelationListItemSkeleton from "@/components/Relations/RelationListItemSkeleton.vue";
import * as _ from 'lodash';
import { namespace } from "vuex-class";
import NoData from "@/components/NoData.vue";
import { openRelation } from "@/Helpers/NavigationHelpers";
const Relations = namespace("Relations/");
let RelationList = class RelationList extends Vue {
    relations;
    isLastPage;
    isLoading;
    isAllRelations;
    loadNewRelations(entries, observer, intersecting) {
        if (intersecting && !this.isLastPage) {
            this.doLoadMoreRelations();
        }
    }
    doLoadMoreRelations() {
        this.$emit('loadMoreRelations');
    }
    get GroupedRelations() {
        return _.chain(this.relations)
            .groupBy(function (relation) {
            return relation.Name ?
                relation.Name.substring(0, 1).toUpperCase() :
                relation.RecordTag.substring(0, 1);
        })
            .map((value, key) => ({ header: key, relations: value }))
            .value();
    }
    selectRelation(relation) {
        openRelation(relation.Id);
    }
};
__decorate([
    Prop({ type: Array, default: null })
], RelationList.prototype, "relations", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], RelationList.prototype, "isLastPage", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], RelationList.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], RelationList.prototype, "isAllRelations", void 0);
RelationList = __decorate([
    Component({
        components: {
            RelationListItem,
            RelationListItemSkeleton,
            NoData
        }
    })
], RelationList);
export default RelationList;
