<!-- eslint-disable no-undef -->
<template>
  <v-container class="pa-0 MapContainer">
    <v-row no-gutters>
      <v-col cols="12">
        <GmapMap
          ref="googleMapRef"
          :center="center"
          :zoom="zoomLevel"
          class="MapContainer"
          style="position: absolute; top: 50px; left: 0; bottom: 0; width: 100%;"
        />
      </v-col>
    </v-row>
  </v-container>
</template>



<script lang="js">
import { GetRelationLocations } from "@/Webservice/Relation";
import { isNumber } from "lodash";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';

import { gmapApi as googleMapsApi, Size, Marker } from 'vue2-google-maps';

export default
    {
        props: {
            relationLocations: {
                default: function(){ return []},
                type: Array
            }
        },
        data()
        {
            return {
                center: { lat: 52.14342919561791, lng: 5.419280112243188 },
                markers: [],
                //addresses: [],
                map: null,
                zoomLevel: 8,
                clusterer: null,
                currentMarkers: [],
            }
        },
        computed: {
            google: googleMapsApi,
            mapReady()
            {
                return this.google && this.map !== null && this.addresses.length > 0;
            },
            addresses()
            {
                if(this.relationLocations == null || this.relationLocations == undefined)
                    return [];

                return this.relationLocations.map(relationLocation =>
                {
                    if (!isNumber(relationLocation.Lat) || !isNumber(relationLocation.Lon))
                    {
                        console.error("Invalid coordinates for relation:" + relationLocation.Id);
                    }
                    return {
                        id: relationLocation.Id,
                        title: relationLocation.Description,
                        label: relationLocation.Address,
                        position: {
                            lat: relationLocation.Lat,
                            lng: relationLocation.Lon
                        }
                    }
                });
            }
        },
        watch: {
            mapReady(value)
            {
                if (!value) return;
                this.plotMarkers();
            }
        },
        mounted()
        {
            this.$refs.googleMapRef.$mapPromise.then((map) =>
            {
                this.map = map;
            });
        },
        methods: {
            plotMarkers()
            {
                if (!this.addresses.length) return;
                const map2 = this.map;
                //Create empty info window, it will be reused
                // eslint-disable-next-line no-undef
                const infoWindow = new google.maps.InfoWindow({

                });

                const openRelationText = this.$t('openRelationFromMap');
                this.markers = this.addresses.map(location =>
                {

                    let marker = new this.google.maps.Marker({
                        position: location.position,
                    });
                    marker.addListener("click", () =>
                    {
                        infoWindow.setContent(`<h6 class="MarkerRelationName">${location.title}</h6> 
                        <p class="text-subtitle-2 mt-1">${location.label}</p> 
                        <p class="text mb-0 MarkerRelationLink" onClick="app.__vue__.$router.push({ name: 'RelationDetails', params: { id: ${location.id} } })">${openRelationText}</p>`);

                        infoWindow.open({ anchor: marker, map2, shouldFocus: false });
                    })
                    return marker;
                });


                this.clusterer = new MarkerClusterer({
                    map: this.map, markers: this.markers, maxZoom: 16, renderer:
                    {
                        render({ count, position }, stats)
                        {
                            // change color if this cluster has more markers than the mean cluster
                            let color = "#038bfb";
                            if (count > 5)
                                color = "#ffbe00";
                            if (count > 20)
                                color = "#fe0002";
                            if (count > 30)
                                color = "#ff00f6";
                            if (count > 40)
                                color = "#b800ff";

                            // create svg url with fill color
                            const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);

                            // create marker using svg icon
                            // eslint-disable-next-line no-undef
                            return new google.maps.Marker({
                                position,
                                icon: {
                                    url: `data:image/svg+xml;base64,${svg}`,
                                    // eslint-disable-next-line no-undef
                                    scaledSize: new google.maps.Size(45, 45),
                                },
                                label: {
                                    text: String(count),
                                    color: "rgba(255,255,255,0.9)",
                                    fontSize: "12px",
                                },
                                // adjust zIndex to be above other markers
                                zIndex: 1000 + count,
                            });
                        }
                    }
                });

                this.google.maps.event.addListener(this.map, "zoom_changed", () =>
                {
                    infoWindow.close();
                });

                this.google.maps.event.addListener(this.map, "bounds_changed", () =>
                {
                    this.clusterer.clearMarkers();
                    this.currentMarkers = this.markers.filter(marker =>
                        marker.getVisible() && this.map.getBounds().contains(marker.getPosition()));
                    this.clusterer.addMarkers(this.currentMarkers);
                });
            }
        },
    };
</script>


<style lang="scss">
.MarkerRelationLink {
  cursor: pointer;
  text-decoration: underline;
}

.MapContainer {
  overscroll-behavior: none;
}

</style>

