import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
import AppointmentDetailsParticipantsListItem from "@/components/Appointments/AppointmentDetailsParticipantsListItem.vue";
import FormGroup from "@/components/FormGroup.vue";
import _ from 'lodash';
let AppointmentDetailsParticipants = class AppointmentDetailsParticipants extends Vue {
    participants;
    get sortedList() {
        return _.orderBy(this.participants, ['title', 'subtitle'], ['asc', 'asc']);
    }
};
__decorate([
    Prop({ type: Array, default: null })
], AppointmentDetailsParticipants.prototype, "participants", void 0);
AppointmentDetailsParticipants = __decorate([
    Component({
        components: {
            FormGroup,
            AppointmentDetailsParticipantsListItem
        },
    })
], AppointmentDetailsParticipants);
export default AppointmentDetailsParticipants;
