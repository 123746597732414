import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
let AppointmentListCard = class AppointmentListCard extends Vue {
    appointment;
    timeOptions = { hour: '2-digit', minute: '2-digit' };
    isDisabled() {
        let date = new Date();
        let appointmentDate = this.appointment.StartTimeAsDate;
        return appointmentDate < date;
    }
    //Check if this appointment has details. If not the divider will be hidden
    hasDetails() {
        if (this.appointment.Relation?.RecordTag || this.appointment.Contact?.RecordTag || this.appointment.Location)
            return true;
        return false;
    }
    get combinedDetails() {
        let detail = "";
        if (this.appointment.Location)
            detail += this.appointment.Location;
        if (detail && this.appointment.Contact)
            detail += ", ";
        if (this.appointment.Contact)
            detail += this.appointment.Contact?.RecordTag;
        return detail;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentListCard.prototype, "appointment", void 0);
AppointmentListCard = __decorate([
    Component({
        components: {},
    })
], AppointmentListCard);
export default AppointmentListCard;
