import getUrl from "@/Helpers/BaseUrl";
import axios from "axios";
export async function getAdministrations(token) {
    const response = await axios.get(getUrl('/settings/v1/administrations'));
    if (response.status >= 200 && response.status < 400) {
        return response.data;
    }
    else {
        throw new Error('reponse status: ' + response.status.toString());
    }
}
