import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import ContactDetails from "@/components/Contacts/ContactDetails.vue";
import { editContact, goBack } from "@/Helpers/NavigationHelpers";
import { State } from "vuex-class";
import { getContactDetails } from "@/Webservice/Contact";
import { Component, Vue, Watch } from "vue-property-decorator";
import ContactDetailsSkeleton from "@/components/Contacts/ContactDetailsSkeleton.vue";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
let ContactDetailsView = class ContactDetailsView extends Vue {
    permissions;
    selectedContact = null;
    async loadContact() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            goBack();
            return;
        }
        let contact = await getContactDetails(id);
        if (contact) {
            this.selectedContact = contact;
        }
        else {
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadContact();
    }
    editContact() {
        if (this.selectedContact) {
            editContact(this.selectedContact.Id);
        }
    }
    onContactChanged(contact) {
        this.selectedContact = contact;
        //this.updateContact(contact);
    }
};
__decorate([
    State
], ContactDetailsView.prototype, "permissions", void 0);
__decorate([
    Watch('$route', { immediate: true, deep: true })
], ContactDetailsView.prototype, "onUrlChange", null);
ContactDetailsView = __decorate([
    Component({
        components: {
            ContactDetails,
            AppBar,
            ContactDetailsSkeleton,
            AppBarResponsiveButton
        },
    })
], ContactDetailsView);
export default ContactDetailsView;
