import { __decorate } from "tslib";
import CRMDataField from "@/components/CRMDataField.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import PhoneNumberMenu from "@/components/PhoneNumberMenu.vue";
import { openContact } from "@/Helpers/NavigationHelpers";
let TodoDetailContact = class TodoDetailContact extends Vue {
    contact;
    openContact(contact) {
        openContact(contact.Id);
    }
    getContactPosition(contact) {
        return contact?.PositionObj?.Description ?? "";
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoDetailContact.prototype, "contact", void 0);
TodoDetailContact = __decorate([
    Component({
        components: {
            CRMDataField,
            PhoneNumberMenu
        },
    })
], TodoDetailContact);
export default TodoDetailContact;
