import { trackAction } from "@/store/Helpers/UserMonitor";
export function openGoogleMaps(address) {
    trackAction("Open Google Maps");
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`, '_blank')?.focus();
}
export function callTo(phoneNumber) {
    trackAction("Call phone number");
    window.open(`tel:${phoneNumber}`);
}
export function sendMail(email) {
    trackAction("Send mail");
    window.open(`mailto:${email}`);
}
export function openWebsite(url) {
    trackAction("Open url");
    if (!url.startsWith("http"))
        url = `http://${url}`;
    window.open(url, '_blank')?.focus();
}
export function openFileUrl(url) {
    openWebsite(url);
}
