import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let TodoEditSkeleton = class TodoEditSkeleton extends Vue {
};
TodoEditSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], TodoEditSkeleton);
export default TodoEditSkeleton;
