import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let TodoListItemSkeleton = class TodoListItemSkeleton extends Vue {
};
TodoListItemSkeleton = __decorate([
    Component({
        components: {},
    })
], TodoListItemSkeleton);
export default TodoListItemSkeleton;
