import { __decorate } from "tslib";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import TodoAvatar from "@/components/Todos/TodoAvatar.vue";
import TodoDetailContact from "@/components/Todos/TodoDetailContact.vue";
import { openRelation } from "@/Helpers/NavigationHelpers";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
let TodoDetailsComponent = class TodoDetailsComponent extends Vue {
    todo;
    isLoadingData;
    getExpirationText() {
        let expirationDate = new Date(0);
        if (this.todo.ActionDateAsDate)
            expirationDate = this.todo.ActionDateAsDate;
        if (this.todo.ActionDate && expirationDate != new Date(0)) {
            return this.$i18n.t('expores-date', {
                expires: this.$i18n.d(expirationDate, 'dateLong')
            }).toString();
        }
        else {
            return this.$i18n.t('never-expires').toString();
        }
    }
    openRelation() {
        if (this.todo.Relation) {
            openRelation(this.todo.Relation.Id);
        }
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoDetailsComponent.prototype, "todo", void 0);
__decorate([
    State
], TodoDetailsComponent.prototype, "isLoadingData", void 0);
TodoDetailsComponent = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            TodoDetailContact,
            TodoAvatar
        },
    })
], TodoDetailsComponent);
export default TodoDetailsComponent;
