import { v4 as uuid } from 'uuid';
class CombinedParticipant {
    id;
    externalParticipant;
    internalParticipant;
    static FromAppointment(appointment) {
        const retVal = [];
        if (appointment.OtherParticipants) {
            appointment.OtherParticipants.forEach(element => {
                retVal.push(new CombinedParticipant(undefined, element));
            });
        }
        if (appointment.ExternalParticipants) {
            appointment.ExternalParticipants.forEach(element => {
                retVal.push(new CombinedParticipant(element, undefined));
            });
        }
        return retVal;
    }
    constructor(externalParticipant, internalParticipant) {
        this.externalParticipant = externalParticipant;
        this.internalParticipant = internalParticipant;
        this.id = uuid();
    }
    get title() {
        if (this.externalParticipant?.Contact) {
            return this.externalParticipant.Contact.RecordTag;
        }
        else if (this.internalParticipant?.Employee) {
            return this.internalParticipant.Employee.RecordTag;
        }
        return "";
    }
    get subTitle() {
        if (this.externalParticipant) {
            let subTitle = "";
            if (this.externalParticipant.Contact?.PositionObj?.Description) {
                subTitle = `${this.externalParticipant.Contact.PositionObj.Description} - `;
            }
            subTitle += this.externalParticipant.Relation?.RecordTag;
            return subTitle;
        }
        else if (this.internalParticipant) {
            return this.internalParticipant.Employee?.Position ?? "";
        }
        return "";
    }
    get cellPhone() {
        if (this.externalParticipant?.Contact) {
            return this.externalParticipant.Contact.CellPhone;
        }
        else if (this.internalParticipant?.Employee) {
            return this.internalParticipant?.Employee.Mobile;
        }
        return "";
    }
    get phone1() {
        if (this.externalParticipant?.Contact) {
            return this.externalParticipant.Contact.PhoneNumber1;
        }
        else if (this.internalParticipant?.Employee) {
            return this.internalParticipant?.Employee.PhoneNumber;
        }
        return "";
    }
    get phone2() {
        if (this.externalParticipant?.Contact) {
            return this.externalParticipant.Contact.PhoneNumber2;
        }
        return "";
    }
    get hasPhoneNumber() {
        return this.cellPhone || this.phone1 || this.phone2 ? true : false;
    }
}
export default CombinedParticipant;
