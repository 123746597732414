import { __decorate } from "tslib";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import RelationContactPicker from "@/components/Relations/RelationContactPicker.vue";
import RelationPicker from "@/components/Relations/RelationPicker.vue";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { maxLength, required } from 'vuelidate/lib/validators';
import { namespace, State } from "vuex-class";
const TodoNS = namespace("Todos/");
const EmployeeNS = namespace("Employees/");
let TodoEditForm = class TodoEditForm extends Vue {
    saving;
    todo;
    isLoadingData;
    loadTodoTypes;
    todoTypes;
    getEmployees;
    employees;
    async mounted() {
        this.loadTodoTypes();
        this.getEmployees();
    }
    onUpdateTodoType(selectedTodoType) {
        this.todo.TodoType = selectedTodoType;
    }
    validateTodoType() {
        this.$v.todo.TodoType?.$touch();
    }
    onUpdateRelation(selectedRelation) {
        this.todo.Relation = selectedRelation;
    }
    onUpdateEmployee(selectedEmployee) {
        this.todo.AssignedTo = selectedEmployee;
    }
    validateEmployee() {
        this.$v.todo.AssignedTo?.$touch();
    }
    get relationName() {
        return this.todo.Relation?.RecordTag ?? "";
    }
    validate() {
        this.$v.$touch();
        return !this.$v.$invalid;
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], TodoEditForm.prototype, "saving", void 0);
__decorate([
    Prop({ type: Object, default: null })
], TodoEditForm.prototype, "todo", void 0);
__decorate([
    State
], TodoEditForm.prototype, "isLoadingData", void 0);
__decorate([
    TodoNS.Action
], TodoEditForm.prototype, "loadTodoTypes", void 0);
__decorate([
    TodoNS.State
], TodoEditForm.prototype, "todoTypes", void 0);
__decorate([
    EmployeeNS.Action
], TodoEditForm.prototype, "getEmployees", void 0);
__decorate([
    EmployeeNS.State
], TodoEditForm.prototype, "employees", void 0);
TodoEditForm = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            RelationPicker,
            RelationContactPicker,
            SkeletonEditField
        },
        validations: {
            todo: {
                Description: {
                    required,
                    maxLength: maxLength(80)
                },
                AssignedTo: {
                    required
                },
                TodoType: {
                    required
                },
            }
        }
    })
], TodoEditForm);
export default TodoEditForm;
