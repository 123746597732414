import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexPersistence from 'vuex-persist';
import _ from 'lodash';
import getUrl from '../Helpers/BaseUrl';
import { Todos } from './Modules/Todos/Todos';
import { Appointments } from './Modules/Appointments/Appointments';
import { Relations } from './Modules/Relations/Relations';
import { Employees } from './Modules/Employees/Employees';
import { datadogRum } from '@datadog/browser-rum';
import { trackAction } from './Helpers/UserMonitor';
import { getInstance as getAuthInstance } from '../plugins/auth/auth';
import { clientId, audience, redirect_uri } from '../../auth_config.json';
import { getAdministrations } from '@/Webservice/Administrations';
import { getPermissions } from '@/Webservice/Permissions';
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    key: 'CRMWeb',
    storage: localStorage,
    reducer: (state) => ({
        permissions: state.permissions,
        apiuserid: state.apiuserid,
        languageOverride: state.languageOverride
    }),
    filter: (mutation) => ['setPermissionsData', 'setApiUserId', 'setLanguageOverride'].includes(mutation.type)
});
function createState() {
    return {
        employeeId: 0,
        language: '',
        languageOverride: '',
        userId: '',
        webserviceHasError: false,
        webserviceError: undefined,
        webserviceStatusCode: 0,
        isLoadingData: false,
        showDrawer: false,
        permissions: undefined,
        lat: 0,
        lon: 0,
        apiuserid: undefined,
        administrations: [],
        isLoadingAdministrations: false,
        isLoadingEmployeeInfo: false
    };
}
export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: createState(),
    modules: {
        Todos,
        Appointments,
        Relations,
        Employees
    },
    mutations: {
        setLocation(state, payload) {
            state.lat = payload.lat;
            state.lon = payload.lon;
        },
        setShowDrawer(state, payload) {
            state.showDrawer = payload;
        },
        setPermissionsData(state, payload) {
            state.permissions = payload;
        },
        setWebserviceError(state, payload) {
            state.webserviceError = payload;
            state.webserviceHasError = true;
        },
        setWebserviceStatusCode(state, payload) {
            state.webserviceStatusCode = payload;
            state.webserviceHasError = true;
        },
        clearWebserviceError(state) {
            state.webserviceError = undefined;
            state.webserviceHasError = false;
            state.webserviceStatusCode = 0;
        },
        setIsLoadingData(state, payload) {
            state.isLoadingData = payload;
        },
        setEmployeeId(state, payload) {
            state.employeeId = payload;
        },
        setUserId(state, payload) {
            state.userId = payload;
        },
        setApiUserId(state, payload) {
            state.apiuserid = payload;
        },
        resetState(state) {
            Object.assign(state, createState());
        },
        setLanguageOverride(state, payload) {
            state.languageOverride = payload;
        }
    },
    actions: {
        async switchAdministration(store, payload) {
            store.commit('setApiUserId', payload.apiUserId);
            await store.dispatch('resetState');
        },
        async fetchAdministrations(store) {
            //Don't reload
            if (store.state.administrations.length > 0)
                return;
            store.state.isLoadingAdministrations = true;
            try {
                try {
                    store.state.administrations = [];
                    const token = await getAuthInstance().getTokenSilently({
                        cacheMode: "on",
                        authorizationParams: {
                            audience: audience,
                            redirect_uri: redirect_uri,
                        }
                    });
                    if (token != null) {
                        store.state.administrations = await getAdministrations(token);
                        if (store.state.administrations == null || store.state.administrations.length == 0) {
                            store.commit('setWebserviceError', { Message: "Unable to get administrations", Error: "Unable to get administrations" });
                        }
                    }
                }
                catch (error) {
                    console.log('Error in getAdministrations(): ' + error);
                }
            }
            finally {
                store.state.isLoadingAdministrations = false;
            }
        },
        async fetchEmployeeId(store) {
            if (store.state.employeeId !== 0 && !_.isEmpty(store.state.userId)) {
                return;
            }
            if (!store.state.apiuserid || store.state.isLoadingEmployeeInfo) {
                return;
            }
            store.state.isLoadingEmployeeInfo = true;
            try {
                const config = {
                    headers: {
                        'x-apiuser-Id': store.state.apiuserid
                    }
                };
                const response = await axios.get(getUrl('/settings/v1/get_app_info?app_name=crmweb'), config);
                if (response.status >= 200 && response.status < 400) {
                    console.log(response.data);
                    store.state.language = response.data.LanguageId;
                    store.state.employeeId = response.data.EmployeeId;
                    store.state.userId = response.data.UserId;
                    const permissions = await getPermissions();
                    store.state.permissions = permissions;
                }
                else if (response.status == 404) {
                    console.error("Employee not found");
                }
                else {
                    console.error("Could not retrieve employee");
                }
            }
            finally {
                store.state.isLoadingEmployeeInfo = false;
            }
        },
        getLocation(state) {
            console.log('getting location');
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => state.commit('setLocation', { lat: position?.coords?.latitude ?? 0, lon: position?.coords?.longitude ?? 0 }));
            }
            else {
                console.log("Geolocation is not supported by this browser.");
            }
        },
        resetState(state) {
            state.commit('resetState');
            state.commit('Todos/resetState');
            state.commit('Appointments/resetState');
            state.commit('Relations/resetState');
            state.commit('Employees/resetState');
        },
        async logout(store) {
            store.commit('setApiUserId', undefined);
            store.commit('setLanguageOverride', '');
            await store.dispatch('resetState');
            trackAction("Logout");
            datadogRum.removeUser();
            await getAuthInstance().logout({ clientId: clientId, logoutParams: { returnTo: redirect_uri } });
        }
    },
    getters: {
        userLanguage(state, getters) {
            if (state.languageOverride)
                return state.languageOverride;
            if (getters.IsAuthenticated && state.language) {
                return state.language;
            }
            //fallback to default
            return navigator.language;
        }
    }
});
