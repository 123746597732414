import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import TodoList from "@/components/Todos/TodoList.vue";
import AppBar from "@/components/AppBar.vue";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import { insertTodo } from "@/Helpers/NavigationHelpers";
import { namespace } from "vuex-class";
const todos = namespace('Todos/');
let Todos = class Todos extends Vue {
    tab = 0;
    createTodo() {
        insertTodo();
    }
};
Todos = __decorate([
    Component({
        components: {
            TodoList,
            AppBar,
            AppBarResponsiveButton
        },
    })
], Todos);
export default Todos;
