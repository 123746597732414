import { __decorate } from "tslib";
import { goBack } from "@/Helpers/NavigationHelpers";
import { getContactDetails } from "@/Webservice/Contact";
import { getTodoDetails } from "@/Webservice/Todo";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Todos = namespace("Todos/");
let TodoDetailsView = class TodoDetailsView extends Vue {
    selectedTodo = null;
    async loadTodo() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            goBack();
            return;
        }
        let todo = await getTodoDetails(id);
        if (todo.Contact && todo.Contact.Id > 0) {
            const contactDetails = await getContactDetails(todo.Contact.Id);
            if (contactDetails.Id > 0)
                todo.Contact = contactDetails;
        }
        if (todo) {
            this.selectedTodo = todo;
        }
        else {
            console.error('Todo not found');
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadTodo();
    }
    onTodoChanged(todo) {
        this.selectedTodo = todo;
    }
};
__decorate([
    Watch('$route', { immediate: true, deep: true })
], TodoDetailsView.prototype, "onUrlChange", null);
TodoDetailsView = __decorate([
    Component({
        components: {},
    })
], TodoDetailsView);
export default TodoDetailsView;
