//
// Use the name version without dash -
// Othervise v-icon will not recognize it as material icon and won't use font ligatures.
//
// Icon font is defined and included by the ui-vuetify module
//
const icons = {
    home: 'home',
    window: 'window',
    cog: 'cog',
    user: 'user',
    envelope: 'envelope',
    sort: 'up8',
    inbox: 'inbox2',
    outbox: 'outbox',
    close: 'cross',
    first: 'first',
    prev: 'backward3',
    next: 'forward',
    expand: 'down7',
    dropdown: 'down7',
    save: 'storage4',
    storage: 'storage4',
    'floppy-disk': 'storage4',
    clear: 'cross'
};
export default icons;
