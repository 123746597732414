import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
let RelationListItem = class RelationListItem extends Vue {
    relation;
    showDivider;
    lat;
    lon;
    get distance() {
        return Math.round(this.relation.getDistanceFromLatLonInKm(this.lat, this.lon));
    }
};
__decorate([
    Prop({ type: Object, default: null })
], RelationListItem.prototype, "relation", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], RelationListItem.prototype, "showDivider", void 0);
__decorate([
    State
], RelationListItem.prototype, "lat", void 0);
__decorate([
    State
], RelationListItem.prototype, "lon", void 0);
RelationListItem = __decorate([
    Component({
        components: {},
    })
], RelationListItem);
export default RelationListItem;
