import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import RelationList from "@/components/Relations/RelationList.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
const RelationsNS = namespace("Relations/");
let Relations = class Relations extends Vue {
    tab = "";
    searchQuery;
    setSearchQuery;
    onSearchQueryChanged(q) {
        this.setSearchQuery(q);
    }
};
__decorate([
    RelationsNS.State
], Relations.prototype, "searchQuery", void 0);
__decorate([
    RelationsNS.Mutation
], Relations.prototype, "setSearchQuery", void 0);
Relations = __decorate([
    Component({
        components: {
            RelationList,
            AppBar,
            AppBarResponsiveButton
        },
        beforeRouteLeave(to, from, next) {
            this.setSearchQuery('');
            next();
            return true;
        }
    })
], Relations);
export default Relations;
