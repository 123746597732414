import { __decorate } from "tslib";
import AppointmentList from "@/components/Appointments/AppointmentList.vue";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import TodoDetailContact from "@/components/Todos/TodoDetailContact.vue";
import { getPageNumber, isLastPage, pageSize, processPageData } from "@/store/Helpers/PageHelpers";
import { GetRelationAppointments } from "@/Webservice/Relation";
import { startOfToday } from "date-fns";
import _ from "lodash";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
let RelationDetailAppointments = class RelationDetailAppointments extends Vue {
    relation;
    historyMode;
    isLoading = false;
    isLastPage = false;
    appointmentsLocal = [];
    onrelationChanged() {
        this.appointmentsLocal = [];
    }
    async onHistoryModeChanged() {
        await this.loadMoreAppointments();
    }
    async mounted() {
        if (this.appointmentsLocal.length == 0) {
            await this.loadMoreAppointments();
        }
    }
    async loadMoreAppointments() {
        try {
            this.isLoading = true;
            const page = getPageNumber(this.appointmentsLocal);
            const relationAppointments = await GetRelationAppointments(this.relation.Id, this.historyMode, page, pageSize);
            this.isLastPage = isLastPage(relationAppointments);
            this.appointmentsLocal = processPageData(this.appointmentsLocal, relationAppointments);
        }
        finally {
            this.isLoading = false;
        }
    }
    get appointments() {
        if (!this.historyMode)
            return _.filter(this.appointmentsLocal, app => new Date(app.StartTime) >= startOfToday());
        else
            return _.filter(this.appointmentsLocal, app => new Date(app.StartTime) < startOfToday());
    }
};
__decorate([
    Prop({ type: Object, default: null })
], RelationDetailAppointments.prototype, "relation", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], RelationDetailAppointments.prototype, "historyMode", void 0);
__decorate([
    Watch("relation")
], RelationDetailAppointments.prototype, "onrelationChanged", null);
__decorate([
    Watch("historyMode")
], RelationDetailAppointments.prototype, "onHistoryModeChanged", null);
RelationDetailAppointments = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            TodoDetailContact,
            AppointmentList
        },
    })
], RelationDetailAppointments);
export default RelationDetailAppointments;
