import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let AppointmentEditSkeleton = class AppointmentEditSkeleton extends Vue {
};
AppointmentEditSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], AppointmentEditSkeleton);
export default AppointmentEditSkeleton;
