import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { datadogRum } from '@datadog/browser-rum';
import Logout from '../views/Logout.vue';
import AppointmentInsert from '../views/AppointmentEdit.vue';
import Appointments from '../views/Appointments.vue';
import AppointmentDetails from '../views/AppointmentDetails.vue';
import AppointmentDetailsView from '../components/Appointments/AppointmentDetailsView.vue';
import AppointmentFollowupWithTodo from '../components/Appointments/AppointmentFollowupWithTodo.vue';
import AppointmentFollowupWithAppointment from '../components/Appointments/AppointmentFollowupWithAppointment.vue';
import AppointmentEdit from '../views/AppointmentEdit.vue';
import ContactDetails from '../views/ContactDetails.vue';
import ContactEdit from '../views/ContactEdit.vue';
import TodoInsert from '../views/TodoEdit.vue';
import MyTodos from '../components/Todos/MyTodos.vue';
import AllTodos from '../components/Todos/AllTodos.vue';
import Todos from '../views/Todos.vue';
import TodoDetails from '../views/TodoDetails.vue';
import TodoDetailsView from '@/components/Todos/TodoDetailsView.vue';
import TodoFollowupWithTodo from '../components/Todos/TodoFollowupWithTodo.vue';
import TodoFollowupWithAppointment from '../components/Todos/TodoFollowupWithAppointment.vue';
import TodoEdit from '../views/TodoEdit.vue';
import MyRelationsMap from '../components/Relations/MyRelationsMap.vue';
import AllRelationsMap from '../components/Relations/AllRelationsMap.vue';
import RelationMap from '../views/RelationMap.vue';
import MyRelations from '../components/Relations/MyRelations.vue';
import AllRelations from '../components/Relations/AllRelations.vue';
import Relations from '../views/Relations.vue';
import RelationDetails from '../views/RelationDetails.vue';
import RelationDetailsComponent from '../components/Relations/RelationDetails.vue';
import RelationDetailTodos from '../components/Relations/RelationDetailTodos.vue';
import RelationDetailAppointments from '../components/Relations/RelationDetailAppointments.vue';
import Styles from '../views/Styles.vue';
import { authGuard } from '@/plugins/auth/authGuard';
import ChooseAdministration from '@/views/ChooseAdministration.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            showInMenu: false,
            menuIcon: 'grid',
            menuOrder: 0,
            menuTranslation: 'menu_home'
        },
        beforeEnter: authGuard
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: true,
            showInMenu: false,
        }
    },
    {
        path: '/chooseadministration',
        name: 'ChooseAdministration',
        component: ChooseAdministration,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false,
        }
    },
    {
        path: '/appointments/new',
        name: 'AppointmentInsert',
        component: AppointmentInsert,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        }
    },
    {
        path: '/appointments',
        name: 'Appointments',
        component: Appointments,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: true,
            menuIcon: 'calendar5',
            menuOrder: 10,
            menuTranslation: 'menu_appointments'
        }
    },
    {
        path: '/appointments/:id',
        name: 'AppointmentDetailsParent',
        component: AppointmentDetails,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        },
        children: [
            {
                path: '/appointments/:id',
                name: 'AppointmentDetails',
                component: AppointmentDetailsView,
            },
            {
                path: 'followup/todo',
                name: 'AppointmentFollowupWithTodo',
                component: AppointmentFollowupWithTodo,
            },
            {
                path: 'followup/appointment',
                name: 'AppointmentFollowupWithAppointment',
                component: AppointmentFollowupWithAppointment,
            },
            {
                path: 'edit',
                name: 'AppointmentEdit',
                component: AppointmentEdit
            },
        ]
    },
    {
        path: '/contact/:id',
        name: 'ContactDetails',
        component: ContactDetails,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        }
    },
    {
        path: '/contact/:id/edit',
        name: 'ContactEdit',
        component: ContactEdit,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        }
    },
    {
        path: '/todos/new',
        name: 'TodoInsert',
        component: TodoInsert,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        }
    },
    {
        path: '/todos',
        children: [
            {
                path: '/todos',
                name: 'MyTodos',
                component: MyTodos,
            },
            {
                path: 'All',
                name: 'AllTodos',
                component: AllTodos,
            }
        ],
        component: Todos,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: true,
            menuIcon: 'clipboard5',
            menuOrder: 20,
            menuTranslation: 'menu_todos'
        }
    },
    {
        path: '/todos/:id',
        name: 'TodoDetailsParent',
        component: TodoDetails,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        },
        children: [
            {
                path: '/todos/:id',
                name: 'TodoDetails',
                component: TodoDetailsView,
            },
            {
                path: 'followup/todo',
                name: 'TodoFollowupWithTodo',
                component: TodoFollowupWithTodo,
            },
            {
                path: 'followup/appointment',
                name: 'TodoFollowupWithAppointment',
                component: TodoFollowupWithAppointment,
            },
        ]
    },
    {
        path: '/todos/:id/edit',
        name: 'TodoEdit',
        component: TodoEdit,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        }
    },
    {
        name: 'relationMap',
        path: '/relations/map',
        redirect: { name: 'MyRelationsMap' },
        children: [
            {
                path: 'mine',
                name: 'MyRelationsMap',
                component: MyRelationsMap,
            },
            {
                path: 'all',
                name: 'AllRelationsMap',
                component: AllRelationsMap,
            }
        ],
        component: RelationMap,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false,
        }
    },
    {
        name: 'relations',
        path: '/relations',
        redirect: { name: 'MyRelations' },
        children: [
            {
                path: '/relations',
                name: 'MyRelations',
                component: MyRelations,
            },
            {
                path: 'All',
                name: 'AllRelations',
                component: AllRelations,
            }
        ],
        component: Relations,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: true,
            menuIcon: 'user',
            menuOrder: 30,
            menuTranslation: 'menu_relations'
        }
    },
    {
        path: '/relations/:relationId/contact/new',
        name: 'ContactInsert',
        component: ContactEdit,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false
        }
    },
    {
        path: '/relations/:id',
        name: 'RelationDetailsParent',
        redirect: { name: 'RelationDetails' },
        component: RelationDetails,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false,
        },
        children: [
            {
                path: 'Details',
                name: 'RelationDetails',
                component: RelationDetailsComponent,
            },
            {
                path: 'Todos',
                name: 'RelationTodos',
                component: RelationDetailTodos,
            },
            {
                path: 'Appointments',
                name: 'RelationAppointments',
                component: RelationDetailAppointments,
            },
            {
                path: 'PastTodos',
                name: 'RelationPastTodos',
                component: RelationDetailTodos,
                props: { "history-mode": true }
            },
            {
                path: 'PastAppointments',
                name: 'RelationPastAppointments',
                component: RelationDetailAppointments,
                props: { "history-mode": true }
            }
        ],
    },
    {
        path: '/styles',
        name: 'Styles',
        component: Styles,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            showInMenu: false,
            menuIcon: 'brush2',
            menuOrder: 1000,
            menuTranslation: 'menu_style'
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    }
});
router.beforeEach((to, from, next) => {
    datadogRum.startView(to.name ?? to.path);
    next();
});
export default router;
