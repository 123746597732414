import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { navigateToRoot } from "@/Helpers/NavigationHelpers";
let ChooseAdministration = class ChooseAdministration extends Vue {
    setShowDrawer;
    setApiUserId;
    setWebserviceError;
    administrations;
    isLoadingAdministrations;
    fetchAdministrations;
    administration = null;
    showDropDown = true;
    mounted() {
        this.setShowDrawer(false);
        this.fetchAdministrations();
    }
    onAdministrationsChanged(newValue, oldValue) {
        if (this.administrations?.length == 1) {
            this.administration = this.administrations[0];
            this.setAdministration();
        }
    }
    async setAdministration() {
        await this.setApiUserId(this.administration?.apiUserId);
        navigateToRoot();
    }
};
__decorate([
    Mutation
], ChooseAdministration.prototype, "setShowDrawer", void 0);
__decorate([
    Mutation
], ChooseAdministration.prototype, "setApiUserId", void 0);
__decorate([
    Mutation
], ChooseAdministration.prototype, "setWebserviceError", void 0);
__decorate([
    State
], ChooseAdministration.prototype, "administrations", void 0);
__decorate([
    State
], ChooseAdministration.prototype, "isLoadingAdministrations", void 0);
__decorate([
    Action
], ChooseAdministration.prototype, "fetchAdministrations", void 0);
__decorate([
    Watch('administrations')
], ChooseAdministration.prototype, "onAdministrationsChanged", null);
ChooseAdministration = __decorate([
    Component({
        components: {},
    })
], ChooseAdministration);
export default ChooseAdministration;
