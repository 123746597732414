import { getPageNumber, processPageData, pageSize, isLastPage } from '../../Helpers/PageHelpers';
import { GetRelationReports, getAllRelations, getMyRelations, searchRelations, GetRelationLocations } from '@/Webservice/Relation';
import { getAllPositions } from '@/Webservice/Contact';
import { trackAction } from '@/store/Helpers/UserMonitor';
function createState() {
    return {
        allRelations: [],
        myRelations: [],
        allSearchResult: [],
        mySearchResult: [],
        searchQuery: "",
        hasMoreAllRelations: true,
        hasMoreMyRelations: true,
        isLoadingAllRelationData: false,
        isLoadingMyRelationData: false,
        reports: [],
        positions: [],
        isLoadingPositions: false,
        myRelationLocations: null,
        allRelationLocations: null
    };
}
export const Relations = {
    namespaced: true,
    state: createState(),
    mutations: {
        setMyRelations(state, payload) {
            state.myRelations = payload;
        },
        setAllRelations(state, payload) {
            state.allRelations = payload;
        },
        setHasMoreMyRelations(state, payload) {
            state.hasMoreMyRelations = payload;
        },
        setHasMoreAllRelations(state, payload) {
            state.hasMoreAllRelations = payload;
        },
        setSearchResultMyRelations(state, payload) {
            state.mySearchResult = payload;
        },
        setSearchResultAllRelations(state, payload) {
            state.allSearchResult = payload;
        },
        setSearchQuery(state, payload) {
            state.searchQuery = payload;
            state.mySearchResult = [];
            state.allSearchResult = [];
            state.hasMoreAllRelations = true;
            state.hasMoreMyRelations = true;
        },
        setIsLoadingAllRelationData(state, payload) {
            state.isLoadingAllRelationData = payload;
        },
        setIsLoadingMyRelationData(state, payload) {
            state.isLoadingMyRelationData = payload;
        },
        resetState(state) {
            Object.assign(state, createState());
        },
        setReports(state, payload) {
            state.reports = payload;
        },
        setPositions(state, payload) {
            state.positions = payload;
        },
        setIsLoadingPositions(state, payload) {
            state.isLoadingPositions = payload;
        },
        setMyRelationLocations(state, payload) {
            state.myRelationLocations = payload;
        },
        setAllRelationLocations(state, payload) {
            state.allRelationLocations = payload;
        }
    },
    actions: {
        async loadMyRelationLocations(store) {
            store.commit("setMyRelationLocations", await GetRelationLocations(store.rootState.employeeId));
        },
        async loadAllRelationLocations(store) {
            store.commit("setAllRelationLocations", await GetRelationLocations(0));
        },
        async searchMyRelations(store, payload) {
            setLoadingMyRelations(store, true);
            try {
                if (!payload) {
                    store.commit("setSearchQuery", payload);
                    return;
                }
                const currentData = store.state.mySearchResult;
                const page = getPageNumber(currentData);
                const newData = await searchRelations(payload, page, pageSize, true);
                const data = processPageData(currentData, newData);
                store.commit("setSearchResultMyRelations", data);
                store.commit("setHasMoreMyRelations", !isLastPage(newData));
            }
            finally {
                trackAction("Search my relations");
                setLoadingMyRelations(store, false);
            }
        },
        async searchAllRelations(store, payload) {
            setLoadingAllRelations(store, true);
            try {
                if (!payload) {
                    store.commit("setSearchQuery", payload);
                    return;
                }
                const currentData = store.state.allSearchResult;
                const page = getPageNumber(currentData);
                const newData = await searchRelations(payload, page, pageSize, false);
                const data = processPageData(currentData, newData);
                store.commit("setSearchResultAllRelations", data);
                store.commit("setHasMoreAllRelations", !isLastPage(newData));
            }
            finally {
                trackAction("Search all relations");
                setLoadingAllRelations(store, false);
            }
        },
        async getMoreMyRelations(store) {
            setLoadingMyRelations(store, true);
            try {
                const page = getPageNumber(store.state.myRelations);
                const response = await getMyRelations(store.rootState.employeeId, page, pageSize);
                const currentData = store.state.myRelations;
                if (response) {
                    store.commit("setMyRelations", processPageData(currentData, response));
                    store.commit("setHasMoreMyRelations", !isLastPage(response));
                }
            }
            finally {
                setLoadingMyRelations(store, false);
            }
        },
        async getMoreAllRelations(store) {
            setLoadingAllRelations(store, true);
            try {
                const page = getPageNumber(store.state.allRelations);
                const response = await getAllRelations(page, pageSize);
                const currentData = store.state.allRelations;
                if (response) {
                    store.commit("setAllRelations", processPageData(currentData, response));
                    store.commit("setHasMoreAllRelations", !isLastPage(response));
                }
            }
            finally {
                setLoadingAllRelations(store, false);
            }
        },
        async getReports(store) {
            const response = await GetRelationReports();
            if (response) {
                store.commit("setReports", response);
            }
        },
        async loadAllPositions(store, payload) {
            setLoadingPositions(store, true);
            try {
                //positions already loaded
                if (store.state.positions.length > 0)
                    return;
                let page = 1;
                let lastPage = false;
                let positions = [];
                while (!lastPage) {
                    const newPositions = await getAllPositions(page, pageSize);
                    lastPage = isLastPage(newPositions);
                    page++;
                    positions = processPageData(positions, newPositions);
                }
                store.commit("setPositions", positions);
            }
            finally {
                setLoadingPositions(store, false);
            }
        }
    }
};
function setLoadingAllRelations(store, isLoading) {
    store.commit("setIsLoadingAllRelationData", isLoading);
}
function setLoadingMyRelations(store, isLoading) {
    store.commit("setIsLoadingMyRelationData", isLoading);
}
function setLoadingPositions(store, isLoading) {
    store.commit("setIsLoadingPositions", isLoading);
}
