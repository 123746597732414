import getUrl from "@/Helpers/BaseUrl";
import { Appointment } from "@/types/Api/appointment";
import { Relation } from "@/types/Api/relation";
import { Todo } from "@/types/Api/todo";
import axios from "axios";
import _ from "lodash";
import { b64toBlob } from "@/Helpers/Base64ToBlob";
import store from '../store';
export async function searchRelations(query, page, pageSize, myRelations) {
    let salesPerson = 0;
    if (myRelations) {
        salesPerson = store.state.employeeId;
    }
    let filter = `Name[like]"${query}" or RecordTag[like]"${query}" or Address.RecordTag[like]"${query}"`;
    filter = encodeURIComponent(filter);
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation?salesperson=${salesPerson}&page=${page}&size=${pageSize}&filter=${filter}`));
    return _.map(response.data, rel => new Relation(rel));
}
export async function getRelationDetails(relationId) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/${relationId}`));
    return new Relation(response.data);
}
export async function GetRelationAppointments(relationId, historicalData, page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/${relationId}/Appointments?page=${page}&size=${pageSize}&historicalData=${historicalData}`));
    return _.map(response.data, app => new Appointment(app));
}
export async function GetRelationTodos(relationId, historicalData, page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/${relationId}/Todos?page=${page}&size=${pageSize}&historicalData=${historicalData}`));
    return _.map(response.data, app => new Todo(app));
}
export async function GetRelationContacts(relationId) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/${relationId}/Contacts`));
    return response.data;
}
export async function getAllRelations(page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation?salesperson=0&page=${page}&size=${pageSize}`));
    return _.map(response.data, rel => new Relation(rel));
}
export async function getMyRelations(employeeId, page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation?salesperson=${employeeId}&page=${page}&size=${pageSize}`));
    return _.map(response.data, rel => new Relation(rel));
}
export async function GetRelationReports() {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/reports`));
    return response.data;
}
export async function GetRelationLocations(employeeId) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/Locations?salesperson=${employeeId}`));
    return response.data;
}
export async function SaveRelationReport(id, reportId) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Relation/${id}/report?reportId=${reportId}`));
    if (response.data) {
        const decoded = b64toBlob(response.data.Data, 'application/pdf');
        return decoded;
    }
    return undefined;
}
