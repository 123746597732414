import { __decorate } from "tslib";
import AppointmentEditView from '@/components/Appointments/AppointmentEditView.vue';
import { roundToNearest } from '@/Helpers/DateHelper';
import { openAppointment } from "@/Helpers/NavigationHelpers";
import { followUpAppointmenWithAppointment, getAppointmentDetails } from "@/Webservice/Appointment";
import { isFuture } from 'date-fns';
import { cloneDeep } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Appointments = namespace("Appointments/");
let AppointmentFollowupWithAppointment = class AppointmentFollowupWithAppointment extends Vue {
    appointment;
    updateAppointment;
    saving = false;
    error = false;
    finished = false;
    appointmentToEdit = null;
    onParentAppointmentChanged() {
        if (this.appointment) {
            this.appointmentToEdit = cloneDeep(this.appointment);
            //Reset id to prevent overwriting existing item
            this.appointmentToEdit.Id = 0;
            if (!isFuture(this.appointmentToEdit.OriginalStartTimeAsDate)) {
                this.appointmentToEdit.OriginalStartTimeAsDate = roundToNearest(new Date());
            }
        }
    }
    async onSaveAppointment() {
        if (!this.appointmentToEdit)
            return;
        this.saving = true;
        const followupAppointment = await followUpAppointmenWithAppointment(this.appointment.Id, this.appointmentToEdit);
        if (followupAppointment?.Id > 0) {
            this.updateAppointment(followupAppointment);
            //reload appointment, probably some field have changed (Done, etc)
            let updatedAppointment = await getAppointmentDetails(this.appointment.Id);
            this.updateAppointment(updatedAppointment);
            this.finished = true;
            openAppointment(followupAppointment.Id, true);
        }
        else {
            this.error = true;
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentFollowupWithAppointment.prototype, "appointment", void 0);
__decorate([
    Appointments.Mutation
], AppointmentFollowupWithAppointment.prototype, "updateAppointment", void 0);
__decorate([
    Watch('appointment')
], AppointmentFollowupWithAppointment.prototype, "onParentAppointmentChanged", null);
AppointmentFollowupWithAppointment = __decorate([
    Component({
        components: {
            AppointmentEditView
        },
    })
], AppointmentFollowupWithAppointment);
export default AppointmentFollowupWithAppointment;
