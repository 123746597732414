import { __decorate } from "tslib";
import { Prop, Component, Vue } from 'vue-property-decorator';
let FormGroup = class FormGroup extends Vue {
    title;
};
__decorate([
    Prop({ type: String, default: "" })
], FormGroup.prototype, "title", void 0);
FormGroup = __decorate([
    Component
], FormGroup);
export default FormGroup;
