import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import RelationList from "@/components/Relations/RelationList.vue";
import AppBar from "@/components/AppBar.vue";
import { namespace } from "vuex-class";
const RelationsNS = namespace("Relations/");
let Relations = class Relations extends Vue {
    allRelations;
    allSearchResult;
    searchQuery;
    searchAllRelations;
    getMoreAllRelations;
    hasMoreAllRelations;
    isLoadingAllRelationData;
    onSearchTextChanged() {
        this.searchAllRelations(this.searchQuery);
    }
    async loadRelations() {
        if (this.searchQuery) {
            await this.searchAllRelations(this.searchQuery);
        }
        else {
            await this.getMoreAllRelations();
        }
    }
    get relationData() {
        if (this.searchQuery) {
            return this.allSearchResult;
        }
        else {
            return this.allRelations;
        }
    }
};
__decorate([
    RelationsNS.State
], Relations.prototype, "allRelations", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "allSearchResult", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "searchQuery", void 0);
__decorate([
    RelationsNS.Action
], Relations.prototype, "searchAllRelations", void 0);
__decorate([
    RelationsNS.Action
], Relations.prototype, "getMoreAllRelations", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "hasMoreAllRelations", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "isLoadingAllRelationData", void 0);
__decorate([
    Watch('searchQuery')
], Relations.prototype, "onSearchTextChanged", null);
Relations = __decorate([
    Component({
        components: {
            RelationList,
            AppBar
        },
    })
], Relations);
export default Relations;
