import { __decorate } from "tslib";
import AppointmentDetailsParticipants from '@/components/Appointments/AppointmentDetailsParticipants.vue';
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import { openGoogleMaps } from '@/Helpers/LinkHelpers';
import { openRelation } from '@/Helpers/NavigationHelpers';
import CombinedParticipant from '@/types/CombinedParticipant';
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
let AppointmentDetailsComponent = class AppointmentDetailsComponent extends Vue {
    appointment;
    isLoadingData;
    get combinedParticipants() {
        return CombinedParticipant.FromAppointment(this.appointment);
    }
    openGoogleMaps() {
        openGoogleMaps(this.appointment.Location);
    }
    openRelation() {
        if (this.appointment.Relation) {
            openRelation(this.appointment.Relation.Id);
        }
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentDetailsComponent.prototype, "appointment", void 0);
__decorate([
    State
], AppointmentDetailsComponent.prototype, "isLoadingData", void 0);
AppointmentDetailsComponent = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            AppointmentDetailsParticipants
        },
    })
], AppointmentDetailsComponent);
export default AppointmentDetailsComponent;
