import { __decorate } from "tslib";
import AppointmentEditView from "@/components/Appointments/AppointmentEditView.vue";
import { goBack, openAppointment } from "@/Helpers/NavigationHelpers";
import { Appointment } from "@/types/Api/appointment";
import { createAppointment, getAppointmentDetails, saveAppointment } from "@/Webservice/Appointment";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Appointments = namespace("Appointments/");
let AppointmentEdit = class AppointmentEdit extends Vue {
    selectedAppointment = null;
    saving = false;
    error = false;
    finished = false;
    appBarTitle = "";
    updateAppointment;
    async loadAppointment() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            this.appBarTitle = this.$t('new-appointment').toString();
            //Create new appointment
            this.selectedAppointment = new Appointment();
            return;
        }
        this.appBarTitle = this.$t('edit-appointment').toString();
        let appointment = await getAppointmentDetails(id);
        if (appointment) {
            this.selectedAppointment = appointment;
        }
        else {
            console.error('Appointment not found');
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadAppointment();
    }
    async onSaveAppointment() {
        if (!this.selectedAppointment)
            return;
        this.saving = true;
        let updatedAppointment = undefined;
        let created = false;
        if (this.selectedAppointment.Id > 0) {
            updatedAppointment = await saveAppointment(this.selectedAppointment);
        }
        else {
            updatedAppointment = await createAppointment(this.selectedAppointment);
            created = true;
        }
        if (updatedAppointment?.Id > 0) {
            this.updateAppointment(updatedAppointment);
            this.finished = true;
            if (created)
                openAppointment(updatedAppointment.Id, true);
            else
                goBack();
        }
        else {
            this.error = true;
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Appointments.Mutation
], AppointmentEdit.prototype, "updateAppointment", void 0);
__decorate([
    Watch('$route', { immediate: true, deep: true })
], AppointmentEdit.prototype, "onUrlChange", null);
AppointmentEdit = __decorate([
    Component({
        components: {
            AppointmentEditView,
        },
    })
], AppointmentEdit);
export default AppointmentEdit;
