import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import { Gender } from "@/types/Api/person";
import { callTo, sendMail } from "@/Helpers/LinkHelpers";
import { openRelation } from "@/Helpers/NavigationHelpers";
import _ from 'lodash';
let ContactDetails = class ContactDetails extends Vue {
    contact;
    genderValue;
    callTo(phoneNumber) {
        callTo(phoneNumber);
    }
    sendMail(email) {
        sendMail(email);
    }
    openRelation(relation) {
        openRelation(relation.Id);
    }
    get genderDescription() {
        let description;
        if (this.contact.Person?.Gender == Gender.Male)
            description = this.$t('gender-male').toString();
        else if (this.contact.Person?.Gender == Gender.Female)
            description = this.$t('gender-female').toString();
        else
            description = this.$t('gender-unknown').toString();
        return description;
    }
    get contactName() {
        let name = "";
        if (!this.contact.Person?.FirstName)
            name += this.contact.Person?.Initials;
        else
            name += this.contact.Person.FirstName;
        name = _.trim(name + " " + this.contact.Person?.NamePrefix, " ");
        name = _.trim(name + " " + this.contact.Person?.LastName, " ");
        return name;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], ContactDetails.prototype, "contact", void 0);
ContactDetails = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup
        },
    })
], ContactDetails);
export default ContactDetails;
