import router from "@/router";
export function editAppointment(appointmentId) {
    router.push({ name: 'AppointmentEdit', params: { id: appointmentId.toString() } });
}
export function openAppointment(appointmentId, replace = false) {
    if (replace) {
        router.replace({ name: 'AppointmentDetails', params: { id: appointmentId.toString() } });
    }
    else {
        router.push({ name: 'AppointmentDetails', params: { id: appointmentId.toString() } });
    }
}
export function followupAppointmentWithAppointment(appointmentId) {
    router.push({ name: 'AppointmentFollowupWithAppointment', params: { id: appointmentId.toString() } });
}
export function followupAppointmentWithTodo(todoId) {
    router.push({ name: 'AppointmentFollowupWithTodo', params: { id: todoId.toString() } });
}
export function followUpTodoWithAppointment(todoId) {
    router.push({ name: 'TodoFollowupWithAppointment', params: { id: todoId.toString() } });
}
export function followupTodoWithTodo(todoId) {
    router.push({ name: 'TodoFollowupWithTodo', params: { id: todoId.toString() } });
}
export function insertAppointment() {
    router.push({ name: 'AppointmentInsert' });
}
export function insertContact(relationId) {
    router.push({ name: 'ContactInsert', params: { relationId: relationId.toString() } });
}
export function editContact(contactId) {
    router.push({ name: 'ContactEdit', params: { id: contactId.toString() } });
}
export function openContact(contactId, replace = false) {
    if (replace) {
        router.replace({ name: 'ContactDetails', params: { id: contactId.toString() } });
    }
    else {
        router.push({ name: 'ContactDetails', params: { id: contactId.toString() } });
    }
}
export function openRelation(relationId) {
    router.push({ name: 'RelationDetails', params: { id: relationId.toString() } });
}
export function editTodo(todoId) {
    router.push({ name: 'TodoEdit', params: { id: todoId.toString() } });
}
export function openTodo(todoId, replace = false) {
    if (replace) {
        router.replace({ name: 'TodoDetails', params: { id: todoId.toString() } });
    }
    else {
        router.push({ name: 'TodoDetails', params: { id: todoId.toString() } });
    }
}
export function insertTodo() {
    router.push({ name: 'TodoInsert' });
}
export function navigateToRoot() {
    router.push({ name: 'Home' });
}
export function navigateToLogout() {
    router.push({ name: 'Logout' });
}
export function goBack() {
    router.go(-1);
}
