import { __decorate } from "tslib";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import TodoDetailContact from "@/components/Todos/TodoDetailContact.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, State, namespace } from "vuex-class";
import PhoneNumberMenu from "@/components/PhoneNumberMenu.vue";
import { callTo, openGoogleMaps, openWebsite, sendMail } from "@/Helpers/LinkHelpers";
import { openContact, insertContact } from "@/Helpers/NavigationHelpers";
const Relations = namespace('Relations/');
let RelationDetails = class RelationDetails extends Vue {
    relation;
    permissions;
    lat;
    lon;
    getLocation;
    mounted() {
        this.getLocation();
    }
    get distance() {
        return Math.round(this.relation.getDistanceFromLatLonInKm(this.lat, this.lon));
    }
    getContactPosition(contact) {
        return contact?.PositionObj?.Description ?? "";
    }
    openWebsite() {
        openWebsite(this.relation.WebsiteUrl);
    }
    callTo(phoneNumber) {
        callTo(phoneNumber);
    }
    openGoogleMaps() {
        openGoogleMaps(this.relation.Address.RecordTag);
    }
    openContact(contact) {
        openContact(contact.Id);
    }
    sendMail() {
        sendMail(this.relation.EmailAddress);
    }
    get relationName() {
        return this.relation?.Name ?? this.relation?.RecordTag ?? "";
    }
    createContact() {
        insertContact(this.relation.Id);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], RelationDetails.prototype, "relation", void 0);
__decorate([
    State
], RelationDetails.prototype, "permissions", void 0);
__decorate([
    State
], RelationDetails.prototype, "lat", void 0);
__decorate([
    State
], RelationDetails.prototype, "lon", void 0);
__decorate([
    Action
], RelationDetails.prototype, "getLocation", void 0);
RelationDetails = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            TodoDetailContact,
            PhoneNumberMenu
        },
    })
], RelationDetails);
export default RelationDetails;
