import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
let ErrorBar = class ErrorBar extends Vue {
    webserviceError;
    webserviceStatusCode;
    webserviceHasError;
    clearWebserviceError;
    dismissError() {
        this.clearWebserviceError();
    }
    get errorMessage() {
        if (this.webserviceError) {
            switch (this.webserviceError.ErrorCode) {
                case 3:
                    return this.$t("InvalidToken").toString();
                case 9:
                    return this.$t("DomainMissing").toString();
                case 6:
                    return this.$t("IQServiceUnavailable").toString();
                case 8:
                    return this.$t("NoLoginData").toString();
                case 2:
                    return this.$t("UserHasNoEmployee").toString();
                case 11:
                    return this.$t("InvalidServerVersion").toString();
                //case 4: // No license available
                // return message, contains specific information about the license
                //case 5:
                // return message, contains the login error
                //case 1:
                // return message, contains specific information about permissions
                //case 0:
                case 10:
                    return this.$t('unknown-domain').toString();
                default:
                    {
                        return this.webserviceError.Error.trim();
                    }
            }
        }
        return "";
    }
};
__decorate([
    State
], ErrorBar.prototype, "webserviceError", void 0);
__decorate([
    State
], ErrorBar.prototype, "webserviceStatusCode", void 0);
__decorate([
    State
], ErrorBar.prototype, "webserviceHasError", void 0);
__decorate([
    Mutation
], ErrorBar.prototype, "clearWebserviceError", void 0);
ErrorBar = __decorate([
    Component({
        components: {},
    })
], ErrorBar);
export default ErrorBar;
