import { __decorate } from "tslib";
import ActionMenu from "@/components/ActionMenu.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { SaveRelationReport } from "@/Webservice/Relation";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ReportViewer from "./ReportViewer.vue";
const RelationNS = namespace("Relations/");
let RelationReportMenu = class RelationReportMenu extends Vue {
    relation;
    getReports;
    reports;
    isLoading = false;
    hasError = false;
    isFinished = false;
    showReport = false;
    pdfData = new ArrayBuffer(0);
    activeReportName = '';
    mounted() {
        if (this.reports.length == 0)
            this.getReports();
    }
    async openReport(report) {
        if (this.relation) {
            this.isLoading = true;
            try {
                const data = await SaveRelationReport(this.relation?.Id, report.Id);
                if (data) {
                    this.activeReportName = `${this.relation.Name} - ${report.ReportName}`;
                    this.pdfData = await data.arrayBuffer();
                    this.showReport = true;
                }
            }
            finally {
                trackAction("Open report", { reportName: report.ReportName });
                this.isLoading = false;
            }
        }
    }
    setFinished() {
        this.isLoading = false;
        this.hasError = false;
        this.isFinished = false;
    }
};
__decorate([
    Prop({ type: Object, default: null, required: true })
], RelationReportMenu.prototype, "relation", void 0);
__decorate([
    RelationNS.Action
], RelationReportMenu.prototype, "getReports", void 0);
__decorate([
    RelationNS.State
], RelationReportMenu.prototype, "reports", void 0);
RelationReportMenu = __decorate([
    Component({
        components: {
            ActionMenu,
            ReportViewer
        },
    })
], RelationReportMenu);
export default RelationReportMenu;
