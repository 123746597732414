import { Person } from "./person";
export class Contact {
    RecordTag;
    EmploymentTerminated;
    PhoneNumber1;
    PhoneNumber2;
    CellPhone;
    Email;
    PositionObj;
    Relation;
    Person;
    Id;
    constructor(dto) {
        this.RecordTag = dto?.RecordTag ?? '';
        this.EmploymentTerminated = dto?.EmploymentTerminated ?? false;
        this.PhoneNumber1 = dto?.PhoneNumber1 ?? '';
        this.PhoneNumber2 = dto?.PhoneNumber2 ?? '';
        this.CellPhone = dto?.CellPhone ?? '';
        this.Email = dto?.Email ?? '';
        this.PositionObj = dto?.PositionObj;
        this.Relation = dto?.Relation;
        this.Person = dto?.Person ?? new Person();
        this.Id = dto?.Id ?? 0;
    }
}
