import TrivestComponents from '@/components/TrivestComponents';
import { addI18nPlugin } from './i18n';
import { addVuelidatePlugin } from './vuelidate';
import { addErrorExtractorPlugin } from './vuelidate-error-extractor';
import * as VueGoogleMaps from 'vue2-google-maps';
import { domain, clientId, audience } from '../../auth_config.json';
import { Auth0Plugin } from './auth';
import router from '../router';
export default function addPlugins(Vue) {
    addI18nPlugin(Vue);
    addVuelidatePlugin(Vue);
    addErrorExtractorPlugin(Vue);
    Vue.use(TrivestComponents);
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBrvFN-oVXnK09Src0nJmI1KsP-5DycbKY',
        },
        installComponents: true
    });
    Vue.use(Auth0Plugin, {
        domain,
        clientId,
        audience,
        onRedirectCallback: (appState) => {
            router.push(appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname);
        }
    });
}
