import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import NoData from "@/components/NoData.vue";
let Styles = class Styles extends Vue {
};
Styles = __decorate([
    Component({
        components: {
            AppBar,
            NoData
        },
    })
], Styles);
export default Styles;
