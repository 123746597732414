import { __decorate } from "tslib";
import { goBack } from '@/Helpers/NavigationHelpers';
import { Prop, Component, Vue, Emit } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import UserMenu from './UserMenu.vue';
let AppBar = class AppBar extends Vue {
    title;
    showBackButton;
    showSearchBox;
    setShowDrawer;
    showDrawer;
    user = null;
    searchQuery = '';
    async mounted() {
        this.user = await this.$auth.getUser();
    }
    get userName() {
        if (this.user != null) {
            return this.user.name;
        }
        return "";
    }
    get hasExtension() {
        return !!this.$slots.extension;
    }
    get currentTitle() {
        if (this.title)
            return this.title;
        else
            return this.$i18n.t('applicationTitle').toString();
    }
    goBack() {
        goBack();
    }
    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }
    get isDesktop() {
        return !this.isMobile;
    }
    searchQueryChanged(q) {
        return q;
    }
};
__decorate([
    Prop({ type: String, default: "" })
], AppBar.prototype, "title", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AppBar.prototype, "showBackButton", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppBar.prototype, "showSearchBox", void 0);
__decorate([
    Mutation
], AppBar.prototype, "setShowDrawer", void 0);
__decorate([
    State
], AppBar.prototype, "showDrawer", void 0);
__decorate([
    Emit('searchQueryChanged')
], AppBar.prototype, "searchQueryChanged", null);
AppBar = __decorate([
    Component({
        components: {
            AppBarResponsiveButton,
            UserMenu
        },
    })
], AppBar);
export default AppBar;
