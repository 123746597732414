import { __decorate } from "tslib";
import AppointmentListCard from "@/components/Appointments/AppointmentListCard.vue";
import AppointmentListCardSkeleton from "@/components/Appointments/AppointmentListCardSkeleton.vue";
import AppointmentListGroupTitle from "@/components/Appointments/AppointmentListGroupTitle.vue";
import NoData from "@/components/NoData.vue";
import { openAppointment } from "@/Helpers/NavigationHelpers";
import { eachDayOfInterval } from "date-fns";
import * as _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Appointments = namespace("Appointments/");
let AppointmentList = class AppointmentList extends Vue {
    appointments;
    isLastPage;
    isLoading;
    sortAsc;
    showAddHint;
    loadNewAppointments(entries, observer, intersecting) {
        if (intersecting && !this.isLastPage) {
            this.doloadMoreAppointments();
        }
    }
    doloadMoreAppointments() {
        this.$emit("loadMoreAppointments");
    }
    get GroupedAppointments() {
        let processedAppointments = this.processAppointments(this.appointments);
        return _.chain(processedAppointments)
            .orderBy(["StartTime", 'OriginalStartTime', "Description"], this.sortAsc ? ['asc'] : ['desc'])
            .groupBy(function (d) {
            return new Date(d.StartTime).toLocaleDateString();
        })
            .map((value, key) => ({ date: key, appointments: value }))
            .value();
    }
    processAppointments(appointments) {
        let processedAppointments = [];
        appointments.forEach(appointment => {
            processedAppointments.push.apply(processedAppointments, this.splitAppointment(appointment));
        });
        return processedAppointments;
    }
    splitAppointment(appointment) {
        const daysOfInterval = eachDayOfInterval({
            start: appointment.OriginalStartTimeAsDate,
            end: appointment.CorrectedOriginalEndDate
        });
        // Single day appointment, return original
        if (daysOfInterval.length == 1)
            return [appointment];
        let splitAppointments = [];
        for (let index = 0; index < daysOfInterval.length; index++) {
            const isMidInterval = index < daysOfInterval.length - 1;
            const dayOfInterval = daysOfInterval[index];
            const nextDayOfInterval = isMidInterval ? daysOfInterval[index + 1] : dayOfInterval;
            // If there is more than 1 appointment and it is not the last page, 
            // splitting should stop at the last appointment.
            // This is required to make paging work!
            // There can be data from the server that should be loaded _before_ our appointments
            if (this.appointments.length != 1 && !this.isLastPage) {
                if (daysOfInterval[index] > this.appointments[this.appointments.length - 1].OriginalStartTimeAsDate)
                    break;
            }
            const splitAppointment = _.cloneDeep(appointment);
            // First day item should have start from original appointment, untill the next day
            if (index == 0) {
                splitAppointment.EndTimeAsDate = nextDayOfInterval;
                splitAppointment.StartTime = appointment.StartTime;
            }
            // Appointments in the middle should have day:00:00:00 untill nextday:00:00:00
            else if (isMidInterval) {
                splitAppointment.StartTimeAsDate = dayOfInterval;
                splitAppointment.EndTimeAsDate = nextDayOfInterval;
            }
            // Last appointments should have start:00:00:00 and end from original appointment
            else {
                splitAppointment.StartTimeAsDate = dayOfInterval;
                splitAppointment.EndTime = appointment.StartTime;
            }
            splitAppointments.push(splitAppointment);
        }
        return splitAppointments;
    }
    selectAppointment(appointment) {
        openAppointment(appointment.Id);
    }
};
__decorate([
    Prop({ type: Array, default: null })
], AppointmentList.prototype, "appointments", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentList.prototype, "isLastPage", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentList.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AppointmentList.prototype, "sortAsc", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AppointmentList.prototype, "showAddHint", void 0);
AppointmentList = __decorate([
    Component({
        components: {
            AppointmentListCard,
            AppointmentListGroupTitle,
            AppointmentListCardSkeleton,
            NoData
        },
    })
], AppointmentList);
export default AppointmentList;
