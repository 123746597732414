import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let ContactDetailsSkeleton = class ContactDetailsSkeleton extends Vue {
    numOfItems = 6;
};
ContactDetailsSkeleton = __decorate([
    Component({
        components: {},
    })
], ContactDetailsSkeleton);
export default ContactDetailsSkeleton;
