import { __decorate } from "tslib";
import AppointmentEditView from '@/components/Appointments/AppointmentEditView.vue';
import { openAppointment } from "@/Helpers/NavigationHelpers";
import { Appointment } from '@/types/Api/appointment';
import { followUpTodoWithAppointment, getTodoDetails } from '@/Webservice/Todo';
import { isFuture } from 'date-fns';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Appointments = namespace("Appointments/");
const Todos = namespace("Todos/");
let TodoFollowupWithAppointment = class TodoFollowupWithAppointment extends Vue {
    todo;
    updateAppointment;
    updateTodo;
    saving = false;
    error = false;
    finished = false;
    appointmentToEdit = null;
    onParentTodoChanged() {
        if (this.todo) {
            this.appointmentToEdit = this.createAppointment(this.todo);
        }
    }
    createAppointment(todo) {
        let appointment = new Appointment();
        appointment.Description = todo.Description;
        if (todo.ActionDateAsDate && isFuture(todo.ActionDateAsDate)) {
            appointment.OriginalStartTimeAsDate = todo.ActionDateAsDate;
        }
        appointment.Relation = todo.Relation;
        appointment.Address = todo.Relation?.Address;
        appointment.Contact = todo.Contact;
        appointment.Done = false;
        appointment.Memo = todo.Memo;
        appointment.Employee = todo.AssignedTo;
        if (todo.Contact) {
            let newParticipant = {
                Relation: todo.Contact.Relation,
                Contact: todo.Contact,
                MainParticipant: false
            };
            appointment.ExternalParticipants = [];
            appointment.ExternalParticipants.push(newParticipant);
        }
        return appointment;
    }
    async onSaveAppointment() {
        if (this.appointmentToEdit != null) {
            this.saving = true;
            const followupAppointment = await followUpTodoWithAppointment(this.todo.Id, this.appointmentToEdit);
            if (followupAppointment?.Id > 0) {
                this.updateAppointment(followupAppointment);
                //reload todo, probably some field have changed (Done, etc)
                let updatedTodo = await getTodoDetails(this.todo.Id);
                this.updateTodo(updatedTodo);
                this.finished = true;
                openAppointment(followupAppointment.Id, true);
            }
            else {
                this.error = true;
            }
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoFollowupWithAppointment.prototype, "todo", void 0);
__decorate([
    Appointments.Mutation
], TodoFollowupWithAppointment.prototype, "updateAppointment", void 0);
__decorate([
    Todos.Action
], TodoFollowupWithAppointment.prototype, "updateTodo", void 0);
__decorate([
    Watch('todo')
], TodoFollowupWithAppointment.prototype, "onParentTodoChanged", null);
TodoFollowupWithAppointment = __decorate([
    Component({
        components: {
            AppointmentEditView
        },
    })
], TodoFollowupWithAppointment);
export default TodoFollowupWithAppointment;
