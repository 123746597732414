import { __decorate } from "tslib";
import { Relation } from '@/types/Api/relation';
import { searchRelations } from '@/Webservice/Relation';
import _, { concat, remove } from 'lodash';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State } from 'vuex-class';
let RelationPicker = class RelationPicker extends Vue {
    value;
    label;
    employeeId;
    isLoadingData = false;
    relations = [];
    showItems = false;
    isDirty = false;
    searchQuery = "";
    debounceFn;
    maxNumberOfRecords = 20;
    get groupedRelations() {
        const myRelations = _.filter(this.relations, rel => rel.SalesPersonId == this.employeeId);
        const otherRelations = _.filter(this.relations, rel => rel.SalesPersonId != this.employeeId);
        let data = [];
        if (myRelations.length > 0) {
            data.push({
                header: this.$t('my-relations')
            });
            data = _.concat(data, myRelations);
        }
        if (otherRelations.length > 0) {
            data.push({
                header: this.$t('other-relations').toString()
            });
            data = _.concat(data, otherRelations);
        }
        return data;
    }
    clear() {
        this.$emit('input', undefined);
    }
    onRelationChanged(val) {
        if (val) {
            this.searchQuery = val.Name;
        }
        else
            this.searchQuery = '';
    }
    onShowItemsChanged(val) {
        if (!val) {
            if (this.value)
                this.searchQuery = this.value.Name;
            else {
                this.searchQuery = '';
            }
        }
    }
    onSearchQueryChange(val, oldVal) {
        this.isDirty = true;
        this.relations = [];
        this.newSearch();
        if (val && val != this.value?.Name) {
            this.showItems = true;
        }
    }
    created() {
        this.debounceFn = _.debounce(this.doSearch, 300, { trailing: true });
    }
    mounted() {
        if (this.value) {
            this.relations = [this.value];
            this.searchQuery = this.value.Name;
        }
    }
    newSearch() {
        if (this.debounceFn != null) {
            this.debounceFn.cancel();
            this.debounceFn();
        }
    }
    async doSearch() {
        try {
            if (this.isLoadingData) {
                return;
            }
            if (!this.searchQuery) {
                return;
            }
            this.isLoadingData = true;
            let data = await searchRelations(this.searchQuery, 1, this.maxNumberOfRecords, true);
            if (data.length < this.maxNumberOfRecords) {
                const dataAllRelations = await searchRelations(this.searchQuery, 1, 20, false);
                remove(dataAllRelations, rel => data.findIndex((myRel) => myRel.Id == rel.Id) >= 0);
                data = concat(data, dataAllRelations);
            }
            this.relations = _.map(data, rel => new Relation(rel));
        }
        finally {
            this.isDirty = false;
            this.isLoadingData = false;
        }
    }
    onUpdateRelation(relation) {
        this.showItems = false;
        this.$emit('input', relation);
    }
};
__decorate([
    Prop({ type: [Object] })
], RelationPicker.prototype, "value", void 0);
__decorate([
    Prop({ type: String })
], RelationPicker.prototype, "label", void 0);
__decorate([
    State
], RelationPicker.prototype, "employeeId", void 0);
__decorate([
    Watch('value')
], RelationPicker.prototype, "onRelationChanged", null);
__decorate([
    Watch('showItems')
], RelationPicker.prototype, "onShowItemsChanged", null);
__decorate([
    Watch('searchQuery')
], RelationPicker.prototype, "onSearchQueryChange", null);
RelationPicker = __decorate([
    Component({
        components: {},
    })
], RelationPicker);
export default RelationPicker;
