import getUrl from "@/Helpers/BaseUrl";
import { Appointment } from "@/types/Api/appointment";
import { Todo } from "@/types/Api/todo";
import axios from "axios";
import _ from "lodash";
export async function saveAppointment(appointment) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Appointment`), appointment);
    return new Appointment(response.data);
}
export async function createAppointment(appointment) {
    const response = await axios.post(getUrl(`/eci/v1/crmweb/Appointment`), appointment);
    return new Appointment(response.data);
}
export async function getAppointmentDetails(appointmentId) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Appointment/${appointmentId}`));
    if (response)
        return new Appointment(response.data);
    else
        return undefined;
}
export async function followUpAppointmenWithAppointment(appointmentId, newAppointment) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Appointment/${appointmentId}/FollowUpAppointment`), newAppointment);
    return new Appointment(response.data);
}
export async function followUpAppointmenWithTodo(appointmentId, newTodo) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Appointment/${appointmentId}/FollowUpTodo`), newTodo);
    return new Todo(response.data);
}
export async function setAppointmentDone(appointmentId) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Appointment/${appointmentId}/Done`), {});
    return new Appointment(response.data);
}
export async function reOpenAppointment(appointmentId) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Appointment/${appointmentId}/Reopen`), {});
    return new Appointment(response.data);
}
export async function getAppointments(employeeId, page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Appointment/?employeeId=${employeeId}&page=${page}&size=${pageSize}`));
    return _.map(response.data, app => new Appointment(app));
}
export async function getAllAppointmentTypes(page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/AppointmentType/?page=${page}&size=${pageSize}`));
    return response.data;
}
