import { __decorate } from "tslib";
import CRMToggleCard from '@/components/CRMToggleCard.vue';
import { Gender } from "@/types/Api/person";
import { Component, ModelSync, Vue } from "vue-property-decorator";
let ContactGenderSelector = class ContactGenderSelector extends Vue {
    genderValue;
    get isGenderMale() {
        return this.genderValue == Gender.Male;
    }
    get isGenderFemale() {
        return this.genderValue == Gender.Female;
    }
    get isGenderUnknown() {
        return this.genderValue == Gender.Unknown;
    }
    setGenderMale() {
        this.genderValue = Gender.Male;
    }
    setGenderFemale() {
        this.genderValue = Gender.Female;
    }
    setGenderUnknown() {
        this.genderValue = Gender.Unknown;
    }
};
__decorate([
    ModelSync('value', 'input', { type: Number, default: Gender.Unknown })
], ContactGenderSelector.prototype, "genderValue", void 0);
ContactGenderSelector = __decorate([
    Component({
        components: {
            CRMToggleCard
        },
    })
], ContactGenderSelector);
export default ContactGenderSelector;
