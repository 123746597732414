import { __decorate } from "tslib";
import _ from 'lodash';
import { Component, Emit, ModelSync, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EmployeeListItem from "./EmployeeListItem.vue";
import EmployeeListItemSkeleton from "./EmployeeListItemSkeleton.vue";
const EmployeeNS = namespace("Employees");
let EmployeeSelectionDialog = class EmployeeSelectionDialog extends Vue {
    showDialogValue;
    participantIds;
    searchQuery = "";
    employees;
    isLoading;
    getEmployees;
    selectedEmployee;
    async mounted() {
        if (this.employees.length == 0) {
            await this.getEmployees();
        }
    }
    hide() {
        this.showDialogValue = false;
    }
    get searchResults() {
        const filteredEmployees = this.employees.filter(emp => !this.participantIds?.find(par => (par === emp.Id)));
        if (this.searchQuery)
            return _.filter(filteredEmployees, emp => emp.RecordTag.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0 ||
                (emp.Position ? emp.Position.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0 : false));
        else
            return filteredEmployees;
    }
    selectEmployee(employee) {
        this.searchQuery = "";
        this.selectedEmployee = employee;
        this.showDialogValue = false;
        this.onEmployeeSelected();
    }
    onEmployeeSelected() {
        return this.selectedEmployee;
    }
};
__decorate([
    ModelSync('showDialog', 'visiblityChanged', { type: Boolean, required: true })
], EmployeeSelectionDialog.prototype, "showDialogValue", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], EmployeeSelectionDialog.prototype, "participantIds", void 0);
__decorate([
    EmployeeNS.State
], EmployeeSelectionDialog.prototype, "employees", void 0);
__decorate([
    EmployeeNS.State
], EmployeeSelectionDialog.prototype, "isLoading", void 0);
__decorate([
    EmployeeNS.Action
], EmployeeSelectionDialog.prototype, "getEmployees", void 0);
__decorate([
    Emit()
], EmployeeSelectionDialog.prototype, "onEmployeeSelected", null);
EmployeeSelectionDialog = __decorate([
    Component({
        components: {
            EmployeeListItem,
            EmployeeListItemSkeleton
        },
    })
], EmployeeSelectionDialog);
export default EmployeeSelectionDialog;
