import { __decorate } from "tslib";
import TodoEditView from '@/components/Todos/TodoEditView.vue';
import { openTodo } from "@/Helpers/NavigationHelpers";
import { followUpTodoWithTodo } from '@/Webservice/Todo';
import { isPast } from 'date-fns';
import { cloneDeep } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Todos = namespace("Todos/");
let TodoFollowupWithTodo = class TodoFollowupWithTodo extends Vue {
    todo;
    updateTodo;
    saving = false;
    error = false;
    finished = false;
    todoToEdit = null;
    onParentTodoChanged() {
        if (this.todo) {
            this.todoToEdit = cloneDeep(this.todo);
            //Reset id to prevent overwriting existing item
            this.todoToEdit.Id = 0;
            //If the original task date is already expired, clear it
            if (this.todoToEdit.ActionDateAsDate) {
                if (isPast(this.todoToEdit.ActionDateAsDate)) {
                    this.todoToEdit.ActionDateAsDate = undefined;
                }
            }
        }
    }
    async onSaveTodo() {
        if (this.todoToEdit) {
            this.saving = true;
            const followupTodo = await followUpTodoWithTodo(this.todo.Id, this.todoToEdit);
            if (followupTodo?.Id > 0) {
                this.updateTodo(followupTodo);
                this.finished = true;
                openTodo(followupTodo.Id, true);
            }
            else {
                this.error = true;
            }
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoFollowupWithTodo.prototype, "todo", void 0);
__decorate([
    Todos.Action
], TodoFollowupWithTodo.prototype, "updateTodo", void 0);
__decorate([
    Watch('todo')
], TodoFollowupWithTodo.prototype, "onParentTodoChanged", null);
TodoFollowupWithTodo = __decorate([
    Component({
        components: {
            TodoEditView
        },
    })
], TodoFollowupWithTodo);
export default TodoFollowupWithTodo;
