import { __decorate } from "tslib";
import { callTo } from "@/Helpers/LinkHelpers";
import { Prop, Component, Vue } from "vue-property-decorator";
let PhoneNumberMenu = class PhoneNumberMenu extends Vue {
    phoneNumber1;
    phoneNumber2;
    cellPhone;
    showTelephoneMenu = false;
    get hasPhoneNumber() {
        return !!this.phoneNumber1 || !!this.phoneNumber2 || !!this.cellPhone;
    }
    callto(phoneNumber) {
        callTo(phoneNumber);
    }
};
__decorate([
    Prop({ type: String, default: "" })
], PhoneNumberMenu.prototype, "phoneNumber1", void 0);
__decorate([
    Prop({ type: String, default: "" })
], PhoneNumberMenu.prototype, "phoneNumber2", void 0);
__decorate([
    Prop({ type: String, default: "" })
], PhoneNumberMenu.prototype, "cellPhone", void 0);
PhoneNumberMenu = __decorate([
    Component({
        components: {},
    })
], PhoneNumberMenu);
export default PhoneNumberMenu;
