import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import AppointmentList from "@/components/Appointments/AppointmentList.vue";
import AppBar from "@/components/AppBar.vue";
import { namespace } from "vuex-class";
import { insertAppointment } from "@/Helpers/NavigationHelpers";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
const Appointments = namespace("Appointments/");
let AppointmentsView = class AppointmentsView extends Vue {
    appointments;
    getMoreAppointments;
    isLastPage;
    isLoadingAppointmentData;
    async mounted() {
        if (this.appointments.length == 0) {
            this.loadMoreAppointments();
        }
    }
    async loadMoreAppointments() {
        await this.getMoreAppointments();
    }
    createAppointment() {
        insertAppointment();
    }
};
__decorate([
    Appointments.State
], AppointmentsView.prototype, "appointments", void 0);
__decorate([
    Appointments.Action
], AppointmentsView.prototype, "getMoreAppointments", void 0);
__decorate([
    Appointments.State
], AppointmentsView.prototype, "isLastPage", void 0);
__decorate([
    Appointments.State
], AppointmentsView.prototype, "isLoadingAppointmentData", void 0);
AppointmentsView = __decorate([
    Component({
        components: {
            AppointmentList,
            AppBar,
            AppBarResponsiveButton
        },
    })
], AppointmentsView);
export default AppointmentsView;
