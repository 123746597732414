import { __decorate } from "tslib";
import { openTodo } from "@/Helpers/NavigationHelpers";
import { Todo } from '@/types/Api/todo';
import { followUpAppointmenWithTodo, getAppointmentDetails } from "@/Webservice/Appointment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TodoEditView from '@/components/Todos/TodoEditView.vue';
import { isFuture } from 'date-fns';
const Todos = namespace("Todos/");
const Employees = namespace("Employees/");
const Appointments = namespace("Appointments/");
let AppointmentFollowupWithTodo = class AppointmentFollowupWithTodo extends Vue {
    appointment;
    updateTodo;
    updateAppointment;
    employees;
    saving = false;
    error = false;
    finished = false;
    todoToEdit = null;
    onParentAppointmentChanged() {
        if (this.appointment) {
            this.todoToEdit = this.createTodo(this.appointment);
        }
    }
    employeesUpdated(newData, old) {
        if (this.todoToEdit) {
            if (old.length == 0 && newData.length > 0) {
                if (!this.todoToEdit.AssignedTo && this.appointment?.Employee) {
                    //Data loaded for first time
                    this.todoToEdit.AssignedTo = this.employees.find(employee => employee.Id == this.appointment.Employee?.Id);
                }
            }
        }
    }
    createTodo(appointment) {
        let todo = new Todo();
        todo.Description = appointment.Description;
        todo.Memo = appointment.Memo ?? '';
        if (appointment.Employee)
            todo.AssignedTo = appointment.Employee;
        todo.Contact = appointment.Contact;
        todo.Relation = appointment.Relation;
        if (isFuture(appointment.OriginalStartTimeAsDate)) {
            todo.ActionDate = appointment.StartTime;
        }
        return todo;
    }
    async onSaveTodo() {
        if (!this.todoToEdit)
            return;
        this.saving = true;
        const followupTodo = await followUpAppointmenWithTodo(this.appointment.Id, this.todoToEdit);
        if (followupTodo?.Id > 0) {
            this.updateTodo(followupTodo);
            //reload appointment, probably some field have changed (Done, etc)
            let updatedAppointment = await getAppointmentDetails(this.appointment.Id);
            this.updateAppointment(updatedAppointment);
            this.finished = true;
            openTodo(followupTodo.Id, true);
        }
        else {
            this.error = true;
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentFollowupWithTodo.prototype, "appointment", void 0);
__decorate([
    Todos.Action
], AppointmentFollowupWithTodo.prototype, "updateTodo", void 0);
__decorate([
    Appointments.Mutation
], AppointmentFollowupWithTodo.prototype, "updateAppointment", void 0);
__decorate([
    Employees.State
], AppointmentFollowupWithTodo.prototype, "employees", void 0);
__decorate([
    Watch('appointment')
], AppointmentFollowupWithTodo.prototype, "onParentAppointmentChanged", null);
__decorate([
    Watch('employees')
], AppointmentFollowupWithTodo.prototype, "employeesUpdated", null);
AppointmentFollowupWithTodo = __decorate([
    Component({
        components: {
            TodoEditView
        },
    })
], AppointmentFollowupWithTodo);
export default AppointmentFollowupWithTodo;
