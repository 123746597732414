var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('form-wrapper',{attrs:{"validator":_vm.$v.contact}},[_c('v-form',{attrs:{"disabled":_vm.saving}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.$t('personal-data')))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('form-validator',{attrs:{"attribute":"last-name","name":"Person.LastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('last-name'),"hide-details":"auto","data-test":"LastName"},on:{"input":function($event){return _vm.$v.contact.Person.LastName.$touch()},"blur":function($event){return _vm.$v.contact.Person.LastName.$touch()}},model:{value:(_vm.contact.Person.LastName),callback:function ($$v) {_vm.$set(_vm.contact.Person, "LastName", $$v)},expression:"contact.Person.LastName"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-validator',{attrs:{"attribute":"name-prefix","name":"Person.NamePrefix"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('name-prefix'),"hide-details":"auto","data-test":"NamePrefix"},on:{"input":function($event){return _vm.$v.contact.Person.NamePrefix.$touch()},"blur":function($event){return _vm.$v.contact.Person.NamePrefix.$touch()}},model:{value:(_vm.contact.Person.NamePrefix),callback:function ($$v) {_vm.$set(_vm.contact.Person, "NamePrefix", $$v)},expression:"contact.Person.NamePrefix"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-validator',{attrs:{"attribute":"first-name","name":"Person.FirstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('first-name'),"hide-details":"auto","data-test":"FirstName"},on:{"input":function($event){return _vm.$v.contact.Person.FirstName.$touch()},"blur":function($event){return _vm.$v.contact.Person.FirstName.$touch()}},model:{value:(_vm.contact.Person.FirstName),callback:function ($$v) {_vm.$set(_vm.contact.Person, "FirstName", $$v)},expression:"contact.Person.FirstName"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-validator',{attrs:{"attribute":"initials","name":"Person.Initials"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('initials'),"hide-details":"auto","data-test":"Initials"},on:{"input":function($event){return _vm.$v.contact.Person.Initials.$touch()},"blur":function($event){return _vm.$v.contact.Person.Initials.$touch()}},model:{value:(_vm.contact.Person.Initials),callback:function ($$v) {_vm.$set(_vm.contact.Person, "Initials", $$v)},expression:"contact.Person.Initials"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-validator',{attrs:{"attribute":"position","name":"Position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestAutoComplete',_vm._b({attrs:{"text-property":"Description","subtitle-property":"Position","value-property":"Code","show-sub-title":true,"items":_vm.positions,"label":_vm.$t('position'),"selected-items":_vm.contact.PositionObj,"loading":_vm.isLoadingPositions,"hide-details":"auto","data-test":"Position"},on:{"update:selectedItems":_vm.onUpdatePosition}},'TrivestAutoComplete',attrs,false))}}])})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.$t('gender')))])]),_c('v-row',[_c('ContactGenderSelector',{attrs:{"data-test":"ContactGenderSelector"},model:{value:(_vm.contact.Person.Gender),callback:function ($$v) {_vm.$set(_vm.contact.Person, "Gender", $$v)},expression:"contact.Person.Gender"}})],1),_c('v-row',{staticClass:"py-0 my-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.$t('contact-details')))])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-validator',{attrs:{"attribute":"main-phone-number","name":"PhoneNumber1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('main-phone-number'),"hide-details":"auto","data-test":"PhoneNumber1"},on:{"input":function($event){return _vm.$v.contact.PhoneNumber1.$touch()},"blur":function($event){return _vm.$v.contact.PhoneNumber1.$touch()}},model:{value:(_vm.contact.PhoneNumber1),callback:function ($$v) {_vm.$set(_vm.contact, "PhoneNumber1", $$v)},expression:"contact.PhoneNumber1"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-validator',{attrs:{"attribute":"alternative-phone-number","name":"PhoneNumber2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('alternative-phone-number'),"hide-details":"auto","data-test":"PhoneNumber2"},on:{"input":function($event){return _vm.$v.contact.PhoneNumber2.$touch()},"blur":function($event){return _vm.$v.contact.PhoneNumber2.$touch()}},model:{value:(_vm.contact.PhoneNumber2),callback:function ($$v) {_vm.$set(_vm.contact, "PhoneNumber2", $$v)},expression:"contact.PhoneNumber2"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('form-validator',{attrs:{"attribute":"cellphone-number","name":"CellPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('cellphone-number'),"hide-details":"auto","data-test":"CellPhone"},on:{"input":function($event){return _vm.$v.contact.CellPhone.$touch()},"blur":function($event){return _vm.$v.contact.CellPhone.$touch()}},model:{value:(_vm.contact.CellPhone),callback:function ($$v) {_vm.$set(_vm.contact, "CellPhone", $$v)},expression:"contact.CellPhone"}},'TrivestTextField',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-validator',{attrs:{"attribute":"e-mail-address","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('TrivestTextField',_vm._b({attrs:{"outlined":"","label":_vm.$t('e-mail-address'),"hide-details":"auto","data-test":"Email"},on:{"input":function($event){return _vm.$v.contact.Email.$touch()},"blur":function($event){return _vm.$v.contact.Email.$touch()}},model:{value:(_vm.contact.Email),callback:function ($$v) {_vm.$set(_vm.contact, "Email", $$v)},expression:"contact.Email"}},'TrivestTextField',attrs,false))}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }