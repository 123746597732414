import { __decorate } from "tslib";
import { GetRelationContacts } from "@/Webservice/Relation";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
let RelationContactPicker = class RelationContactPicker extends Vue {
    valueSync;
    relation;
    relationContacts = [];
    isLoadingRelationContacts = false;
    async onRelationChange(val, oldVal) {
        if (this.valueSync && oldVal) {
            this.valueSync = undefined;
            this.onUpdateContact(undefined);
        }
        await this.loadRelationContacts();
    }
    async loadRelationContacts() {
        if (this.isLoadingRelationContacts)
            return;
        if (!this.relation) {
            this.relationContacts = [];
            return;
        }
        this.isLoadingRelationContacts = true;
        this.relationContacts = [];
        try {
            let data = await GetRelationContacts(this.relation.Id);
            this.relationContacts = data;
        }
        finally {
            this.isLoadingRelationContacts = false;
        }
    }
    onUpdateContact(contact) {
        this.$emit('input', contact);
    }
};
__decorate([
    PropSync('value', { type: Object })
], RelationContactPicker.prototype, "valueSync", void 0);
__decorate([
    Prop({ type: Object, default: null })
], RelationContactPicker.prototype, "relation", void 0);
__decorate([
    Watch('relation', { immediate: true, deep: true })
], RelationContactPicker.prototype, "onRelationChange", null);
RelationContactPicker = __decorate([
    Component({
        components: {},
    })
], RelationContactPicker);
export default RelationContactPicker;
