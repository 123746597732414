import { formatDateForJSON, parseDate } from "@/Helpers/DateHelper";
export class Todo {
    Description;
    Memo;
    ActionDate;
    FinishDate;
    TodoType;
    Relation;
    Contact;
    AssignedTo;
    WorkflowState;
    State;
    CanAssign;
    CanSetDone;
    CanFollowUp;
    CanOpen;
    CanSetWaitingForResponse;
    Id;
    constructor(dto) {
        this.Description = dto?.Description ?? '';
        this.Memo = dto?.Memo ?? '';
        this.ActionDate = dto?.ActionDate ?? null;
        this.FinishDate = dto?.FinishDate ?? null;
        this.TodoType = dto?.TodoType;
        this.Relation = dto?.Relation;
        this.Contact = dto?.Contact;
        this.AssignedTo = dto?.AssignedTo;
        this.WorkflowState = dto?.WorkflowState ?? '';
        this.State = dto?.State ?? 0;
        this.CanAssign = dto?.CanAssign ?? false;
        this.CanSetDone = dto?.CanSetDone ?? false;
        this.CanFollowUp = dto?.CanFollowUp ?? false;
        this.CanOpen = dto?.CanOpen ?? false;
        this.CanSetWaitingForResponse = dto?.CanSetWaitingForResponse ?? false;
        this.Id = dto?.Id ?? 0;
    }
    get ActionDateAsDate() {
        if (this.ActionDate)
            return parseDate(this.ActionDate);
        return undefined;
    }
    set ActionDateAsDate(value) {
        if (!value) {
            this.ActionDate = undefined;
            return;
        }
        this.ActionDate = formatDateForJSON(value);
    }
    get FinishDateAsDate() {
        if (this.FinishDate)
            return parseDate(this.FinishDate);
        return undefined;
    }
    set FinishDateAsDate(value) {
        if (!value) {
            this.FinishDate = undefined;
            return;
        }
        this.FinishDate = formatDateForJSON(value);
    }
}
export var TodoState;
(function (TodoState) {
    TodoState[TodoState["Open"] = 1] = "Open";
    TodoState[TodoState["Assigned"] = 2] = "Assigned";
    TodoState[TodoState["WaitingForResponse"] = 3] = "WaitingForResponse";
    TodoState[TodoState["Done"] = 4] = "Done";
})(TodoState || (TodoState = {}));
