import getUrl from "@/Helpers/BaseUrl";
import { Contact } from "@/types/Api/contact";
import axios from "axios";
import _ from "lodash";
export async function saveContact(contact) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Contact/${contact.Id}`), contact);
    return new Contact(response.data);
}
export async function createContact(contact) {
    const response = await axios.post(getUrl(`/eci/v1/crmweb/Contact`), contact);
    return new Contact(response.data);
}
export async function searchContacts(q, page, pageSize) {
    if (!q || q.length < 3)
        return [];
    let filter = `RecordTag[like]"${q}" or Relation.Name[like]"${q}" or Relation.RecordTag[like]"${q}"`;
    filter = encodeURIComponent(filter);
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Contact/Search?page=${page}&size=${pageSize}&filter=${filter}`));
    return _.map(response.data, app => new Contact(app));
}
export async function getContactDetails(id) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Contact/${id}`));
    return new Contact(response.data);
}
export async function getAllPositions(page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Position?page=${page}&size=${pageSize}`));
    return response.data;
}
