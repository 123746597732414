import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
let TodoEditAppBar = class TodoEditAppBar extends Vue {
    loading;
    error;
    finished;
    appBarTitle;
    onSaveTodo() {
        this.$emit('onSave');
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], TodoEditAppBar.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], TodoEditAppBar.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], TodoEditAppBar.prototype, "finished", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], TodoEditAppBar.prototype, "appBarTitle", void 0);
TodoEditAppBar = __decorate([
    Component({
        components: {
            AppBar,
            AppBarResponsiveButton
        },
    })
], TodoEditAppBar);
export default TodoEditAppBar;
