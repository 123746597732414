import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
let AppointmentListGroupTitle = class AppointmentListGroupTitle extends Vue {
    date;
    dayOptions = { weekday: 'long' };
    dateOptions = { month: 'long', day: 'numeric' };
};
__decorate([
    Prop({ type: Date, default: null })
], AppointmentListGroupTitle.prototype, "date", void 0);
AppointmentListGroupTitle = __decorate([
    Component({
        components: {},
    })
], AppointmentListGroupTitle);
export default AppointmentListGroupTitle;
