import { __decorate } from "tslib";
import CRMDataField from "@/components/CRMDataField.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import PhoneNumberMenu from "@/components/PhoneNumberMenu.vue";
import { openContact } from "@/Helpers/NavigationHelpers";
let AppointmentDetailsParticipantsListItem = class AppointmentDetailsParticipantsListItem extends Vue {
    participant;
    showTelephoneMenu = false;
    openContact() {
        if (this.participant.externalParticipant) {
            openContact(this.participant.externalParticipant.Contact.Id);
        }
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentDetailsParticipantsListItem.prototype, "participant", void 0);
AppointmentDetailsParticipantsListItem = __decorate([
    Component({
        components: {
            CRMDataField,
            PhoneNumberMenu
        },
    })
], AppointmentDetailsParticipantsListItem);
export default AppointmentDetailsParticipantsListItem;
