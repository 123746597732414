import { __decorate } from "tslib";
import ActionMenu from "@/components/ActionMenu.vue";
import AppBar from "@/components/AppBar.vue";
import { followupAppointmentWithAppointment, followupAppointmentWithTodo } from "@/Helpers/NavigationHelpers";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { reOpenAppointment, setAppointmentDone } from "@/Webservice/Appointment";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let AppointmentDetailsView = class AppointmentDetailsView extends Vue {
    appointment;
    isLoading = false;
    hasError = false;
    isFinished = false;
    async setAppointmentDone() {
        try {
            this.isLoading = true;
            if (this.appointment) {
                const changedAppointment = await setAppointmentDone(this.appointment.Id);
                return changedAppointment;
            }
            return this.appointment;
        }
        catch {
            //TODO: Display error
            this.hasError = true;
        }
        finally {
            trackAction("Set appointment done");
            this.isFinished = true;
        }
    }
    followUpWithAppointment() {
        trackAction("Follow up appointment with appointment");
        if (this.appointment)
            followupAppointmentWithAppointment(this.appointment?.Id);
    }
    followUpWithTodo() {
        trackAction("Follow up appointment with todo");
        if (this.appointment)
            followupAppointmentWithTodo(this.appointment?.Id);
    }
    async reOpenAppointment() {
        try {
            this.isLoading = true;
            if (this.appointment) {
                const changedAppointment = await reOpenAppointment(this.appointment.Id);
                return changedAppointment;
            }
            return this.appointment;
        }
        catch {
            //TODO: Display error
            this.hasError = true;
        }
        finally {
            trackAction("Re-open appointment");
            this.isFinished = true;
        }
    }
    setFinished() {
        this.isLoading = false;
        this.hasError = false;
        this.isFinished = false;
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], AppointmentDetailsView.prototype, "appointment", void 0);
__decorate([
    Emit("onAppointmentChanged")
], AppointmentDetailsView.prototype, "setAppointmentDone", null);
__decorate([
    Emit("onAppointmentChanged")
], AppointmentDetailsView.prototype, "reOpenAppointment", null);
AppointmentDetailsView = __decorate([
    Component({
        components: {
            AppBar,
            ActionMenu
        },
    })
], AppointmentDetailsView);
export default AppointmentDetailsView;
