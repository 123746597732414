import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
let TodoListGroupTitle = class TodoListGroupTitle extends Vue {
    date;
    hasDate() {
        if (!this.date)
            return false;
        return this.date.getTime() != 0 ?? false;
    }
};
__decorate([
    Prop({ type: Date, default: undefined })
], TodoListGroupTitle.prototype, "date", void 0);
TodoListGroupTitle = __decorate([
    Component({
        components: {},
    })
], TodoListGroupTitle);
export default TodoListGroupTitle;
