import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import RelationList from "@/components/Relations/RelationList.vue";
import AppBar from "@/components/AppBar.vue";
import { namespace } from "vuex-class";
const RelationsNS = namespace("Relations/");
let Relations = class Relations extends Vue {
    myRelations;
    mySearchResult;
    searchQuery;
    searchMyRelations;
    getMoreMyRelations;
    hasMoreMyRelations;
    isLoadingMyRelationData;
    mySearchTextChanged() {
        this.searchMyRelations(this.searchQuery);
    }
    async loadRelations() {
        if (this.searchQuery) {
            await this.searchMyRelations(this.searchQuery);
        }
        else {
            await this.getMoreMyRelations();
        }
    }
    get relationData() {
        if (this.searchQuery) {
            return this.mySearchResult;
        }
        else {
            return this.myRelations;
        }
    }
};
__decorate([
    RelationsNS.State
], Relations.prototype, "myRelations", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "mySearchResult", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "searchQuery", void 0);
__decorate([
    RelationsNS.Action
], Relations.prototype, "searchMyRelations", void 0);
__decorate([
    RelationsNS.Action
], Relations.prototype, "getMoreMyRelations", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "hasMoreMyRelations", void 0);
__decorate([
    RelationsNS.State
], Relations.prototype, "isLoadingMyRelationData", void 0);
__decorate([
    Watch('searchQuery')
], Relations.prototype, "mySearchTextChanged", null);
Relations = __decorate([
    Component({
        components: {
            RelationList,
            AppBar
        },
    })
], Relations);
export default Relations;
