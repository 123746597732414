import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import AppBar from "@/components/AppBar.vue";
let Logout = class Logout extends Vue {
    logout;
    mounted() {
        this.logout();
    }
};
__decorate([
    Action
], Logout.prototype, "logout", void 0);
Logout = __decorate([
    Component({
        components: {
            AppBar
        },
    })
], Logout);
export default Logout;
