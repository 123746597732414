export var Gender;
(function (Gender) {
    Gender[Gender["Male"] = 1] = "Male";
    Gender[Gender["Female"] = 2] = "Female";
    Gender[Gender["Unknown"] = 3] = "Unknown";
})(Gender || (Gender = {}));
export class Person {
    FirstName;
    Initials;
    LastName;
    NamePrefix;
    Gender;
    Id;
    constructor(dto) {
        this.FirstName = dto?.FirstName ?? '';
        this.Initials = dto?.Initials ?? '';
        this.LastName = dto?.LastName ?? '';
        this.NamePrefix = dto?.NamePrefix ?? '';
        this.Gender = dto?.Gender ?? Gender.Unknown;
        this.Id = dto?.Id ?? 0;
    }
}
