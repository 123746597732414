import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { trackAction } from "@/store/Helpers/UserMonitor";
import { navigateToLogout } from '@/Helpers/NavigationHelpers';
let UserMenu = class UserMenu extends Vue {
    setLanguageOverride;
    showDrawer;
    userLanguage;
    administrations;
    isLoadingAdministrations;
    fetchAdministrations;
    switchAdministration;
    apiuserid;
    logout;
    user = null;
    async mounted() {
        this.user = await this.$auth.getUser();
        this.fetchAdministrations();
    }
    setNewAdministration(newAdministration) {
        this.switchAdministration(newAdministration);
        //Force reload of page
        this.$router.go(0);
    }
    isCurrentAdministration(newAdministration) {
        return this.apiuserid == newAdministration.apiUserId;
    }
    get version() {
        return 'v ' + process.env.VUE_APP_VERSION;
    }
    get userName() {
        return this.user?.name;
    }
    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }
    get isDesktop() {
        return !this.isMobile;
    }
    get isAuthenticated() {
        return this.$auth.isAuthenticated;
    }
    async signout() {
        await navigateToLogout();
    }
    setLanguage(lang) {
        this.setLanguageOverride(lang);
        trackAction(`Set language override ${lang}`);
    }
    isActiveLanguage(lang) {
        return this.userLanguage.startsWith(lang);
    }
};
__decorate([
    Mutation
], UserMenu.prototype, "setLanguageOverride", void 0);
__decorate([
    State
], UserMenu.prototype, "showDrawer", void 0);
__decorate([
    Getter
], UserMenu.prototype, "userLanguage", void 0);
__decorate([
    State
], UserMenu.prototype, "administrations", void 0);
__decorate([
    State
], UserMenu.prototype, "isLoadingAdministrations", void 0);
__decorate([
    Action
], UserMenu.prototype, "fetchAdministrations", void 0);
__decorate([
    Action
], UserMenu.prototype, "switchAdministration", void 0);
__decorate([
    State
], UserMenu.prototype, "apiuserid", void 0);
__decorate([
    Action
], UserMenu.prototype, "logout", void 0);
UserMenu = __decorate([
    Component({
        components: {},
    })
], UserMenu);
export default UserMenu;
