import { getPageNumber, processPageData, pageSize, isLastPage } from '../../Helpers/PageHelpers';
import { getAllTodos, getAllTodoTypes, getMyTodos } from '@/Webservice/Todo';
import _ from 'lodash';
function createState() {
    return {
        myTodos: [],
        allTodos: [],
        todoTypes: [],
        hasMoreMyTodos: false,
        hasMoreAllTodos: false,
        isLoadingAllTodoData: false,
        isLoadingMyTodoData: false,
        isLoadingTodoTypes: false
    };
}
export const Todos = {
    namespaced: true,
    state: createState(),
    mutations: {
        setMyTodos(state, payload) {
            state.myTodos = payload;
        },
        setAllTodos(state, payload) {
            state.allTodos = payload;
        },
        setTodoTypes(state, payload) {
            state.todoTypes = payload;
        },
        setHasMoreMyTodos(state, payload) {
            state.hasMoreMyTodos = payload;
        },
        setHasMoreAllTodos(state, payload) {
            state.hasMoreAllTodos = payload;
        },
        setIsLoadingAllTodoData(state, payload) {
            state.isLoadingAllTodoData = payload;
        },
        setIsLoadingMyTodoData(state, payload) {
            state.isLoadingMyTodoData = payload;
        },
        setIsLoadingTodoTypes(state, payload) {
            state.isLoadingTodoTypes = payload;
        },
        updateTodoInAllTodos(state, todo) {
            if (todo) {
                const idx = _.findIndex(state.allTodos, app => app.Id == todo.Id);
                if (idx >= 0)
                    state.allTodos.splice(idx, 1, todo);
                else
                    state.allTodos.push(todo);
            }
        },
        updateTodoInMyTodos(state, todo) {
            if (todo) {
                const idx = _.findIndex(state.myTodos, app => app.Id == todo.Id);
                if (idx >= 0)
                    state.myTodos.splice(idx, 1, todo);
                else
                    state.myTodos.push(todo);
            }
        },
        deleteTodoInMyTodos(state, todo) {
            if (todo) {
                const idx = _.findIndex(state.myTodos, app => app.Id == todo.Id);
                if (idx >= 0)
                    state.myTodos.splice(idx, 1);
            }
        },
        resetState(state) {
            Object.assign(state, createState());
        }
    },
    actions: {
        async updateTodo(store, payload) {
            // store not yet loaded or empty, so no need to update/create
            // data will be loaded when going to relevant page because it is empty
            if (store.state.allTodos.length > 0) {
                store.commit("updateTodoInAllTodos", payload);
            }
            if (store.state.myTodos.length > 0) {
                if (payload?.AssignedTo?.Id == store.rootState.employeeId) {
                    store.commit("updateTodoInMyTodos", payload);
                }
                else {
                    store.commit("deleteTodoInMyTodos", payload);
                }
            }
        },
        async loadTodoTypes(store, payload) {
            setLoadingTodosTypes(store, true);
            try {
                //todo types already loaded
                if (store.state.todoTypes.length > 0)
                    return;
                let page = 1;
                let lastPage = false;
                let todoTypes = [];
                while (!lastPage) {
                    const newTodoTypes = await getAllTodoTypes(page, pageSize);
                    lastPage = isLastPage(newTodoTypes);
                    page++;
                    todoTypes = processPageData(todoTypes, newTodoTypes);
                }
                store.commit("setTodoTypes", todoTypes);
            }
            finally {
                setLoadingTodosTypes(store, false);
            }
        },
        async getMyTodos(store) {
            setLoadingMyTodos(store, true);
            try {
                const page = getPageNumber(store.state.myTodos);
                const response = await getMyTodos(store.rootState.employeeId, page, pageSize);
                const currentData = store.state.myTodos;
                if (response) {
                    store.commit("setMyTodos", processPageData(currentData, response));
                    store.commit("setHasMoreMyTodos", !isLastPage(response));
                }
            }
            finally {
                setLoadingMyTodos(store, false);
            }
        },
        async getAllTodos(store) {
            setLoadingAllTodos(store, true);
            try {
                const page = getPageNumber(store.state.allTodos);
                const response = await getAllTodos(page, pageSize);
                const currentData = store.state.allTodos;
                if (response) {
                    store.commit("setAllTodos", processPageData(currentData, response));
                    store.commit("setHasMoreAllTodos", !isLastPage(response));
                }
            }
            finally {
                setLoadingAllTodos(store, false);
            }
        },
    }
};
function setLoadingAllTodos(store, isLoading) {
    store.commit("setIsLoadingAllTodoData", isLoading);
}
function setLoadingMyTodos(store, isLoading) {
    store.commit("setIsLoadingMyTodoData", isLoading);
}
function setLoadingTodosTypes(store, isLoading) {
    store.commit("setIsLoadingTodoTypes", isLoading);
}
