import { __decorate } from "tslib";
import ActionMenu from "@/components/ActionMenu.vue";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
let ReportViewer = class ReportViewer extends Vue {
    reportName;
    pdfData;
    showReport;
};
__decorate([
    Prop({ type: String, default: 'report', required: true })
], ReportViewer.prototype, "reportName", void 0);
__decorate([
    Prop({ type: ArrayBuffer, required: true })
], ReportViewer.prototype, "pdfData", void 0);
__decorate([
    ModelSync('value', 'input', { type: Boolean, default: false })
], ReportViewer.prototype, "showReport", void 0);
ReportViewer = __decorate([
    Component({
        components: {
            ActionMenu,
            VuePdfApp
        },
    })
], ReportViewer);
export default ReportViewer;
