import { __decorate } from "tslib";
import ActionMenu from "@/components/ActionMenu.vue";
import { followUpTodoWithAppointment, followupTodoWithTodo } from "@/Helpers/NavigationHelpers";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { reOpenTodo, setTodoDone, setTodoWaitingForReply } from "@/Webservice/Todo";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Todos = namespace("Todos/");
let TodoActionsMenu = class TodoActionsMenu extends Vue {
    todo;
    isLoading = false;
    hasError = false;
    isFinished = false;
    onFollowUpTodoWithAppointment() {
        trackAction("Follow up todo with appointment");
        if (this.todo) {
            followUpTodoWithAppointment(this.todo.Id);
        }
    }
    onFollowUpTodoWithTodo() {
        trackAction("Follow up todo with todo");
        if (this.todo)
            followupTodoWithTodo(this.todo.Id);
    }
    async onSetTodoDone() {
        try {
            this.isLoading = true;
            if (this.todo) {
                const changedTodo = await setTodoDone(this.todo.Id);
                return changedTodo;
            }
            return this.todo;
        }
        catch {
            //TODO: Display error
            this.hasError = true;
        }
        finally {
            trackAction("Set todo done");
            this.isFinished = true;
        }
    }
    async onReOpenTodo() {
        try {
            this.isLoading = true;
            if (this.todo) {
                const changedTodo = await reOpenTodo(this.todo.Id);
                return changedTodo;
            }
            return this.todo;
        }
        catch {
            //TODO: Display error
            this.hasError = true;
        }
        finally {
            trackAction("Re-open todo");
            this.isFinished = true;
        }
    }
    async onSetTodoWaitingForResponse() {
        try {
            this.isLoading = true;
            if (this.todo) {
                const changedTodo = await setTodoWaitingForReply(this.todo.Id);
                return changedTodo;
            }
            return this.todo;
        }
        catch {
            this.hasError = true;
        }
        finally {
            trackAction("Set todo waiting for response");
            this.isFinished = true;
        }
    }
    setFinished() {
        this.isLoading = false;
        this.hasError = false;
        this.isFinished = false;
    }
};
__decorate([
    Prop({ type: Object, default: null, required: true })
], TodoActionsMenu.prototype, "todo", void 0);
__decorate([
    Emit("onTodoChanged")
], TodoActionsMenu.prototype, "onSetTodoDone", null);
__decorate([
    Emit("onTodoChanged")
], TodoActionsMenu.prototype, "onReOpenTodo", null);
__decorate([
    Emit("onTodoChanged")
], TodoActionsMenu.prototype, "onSetTodoWaitingForResponse", null);
TodoActionsMenu = __decorate([
    Component({
        components: {
            ActionMenu
        },
    })
], TodoActionsMenu);
export default TodoActionsMenu;
