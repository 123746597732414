import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let AppointmentListCardSkeleton = class AppointmentListCardSkeleton extends Vue {
};
AppointmentListCardSkeleton = __decorate([
    Component({
        components: {},
    })
], AppointmentListCardSkeleton);
export default AppointmentListCardSkeleton;
