import { __decorate } from "tslib";
import NoData from "@/components/NoData.vue";
import TodoListGroupTitle from "@/components/Todos/TodoListGroupTitle.vue";
import TodoListItem from "@/components/Todos/TodoListItem.vue";
import TodoListItemSkeleton from "@/components/Todos/TodoListItemSkeleton.vue";
import { openTodo } from "@/Helpers/NavigationHelpers";
import * as _ from 'lodash';
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Todos = namespace('Todos/');
let TodoList = class TodoList extends Vue {
    todos;
    isLastPage;
    isLoading;
    sortAsc;
    showAddHint;
    loadNewTodos(entries, observer, intersecting) {
        if (intersecting && !this.isLastPage) {
            this.doLoadMoreTodos();
        }
    }
    async doLoadMoreTodos() {
        this.$emit("loadMoreTodos");
    }
    get GroupedTodos() {
        return _.chain(this.todos)
            .orderBy(['ActionDate'], this.sortAsc ? ['asc'] : ['desc'])
            .groupBy(function (d) { return new Date(d.ActionDate ?? 0).toLocaleDateString(); })
            .map((value, key) => ({ date: key, todos: value }))
            .value();
    }
    selectTodo(todo) {
        openTodo(todo.Id);
    }
};
__decorate([
    Prop({ type: Array, default: null })
], TodoList.prototype, "todos", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TodoList.prototype, "isLastPage", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TodoList.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TodoList.prototype, "sortAsc", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TodoList.prototype, "showAddHint", void 0);
TodoList = __decorate([
    Component({
        components: {
            TodoListGroupTitle,
            TodoListItem,
            TodoListItemSkeleton,
            NoData
        }
    })
], TodoList);
export default TodoList;
