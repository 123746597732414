import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
let RelationListItem = class RelationListItem extends Vue {
    employee;
};
__decorate([
    Prop({ type: Object, default: null })
], RelationListItem.prototype, "employee", void 0);
RelationListItem = __decorate([
    Component({
        components: {},
    })
], RelationListItem);
export default RelationListItem;
