import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let TodoDetailsSkeleton = class TodoDetailsSkeleton extends Vue {
    numOfItems = 3;
};
TodoDetailsSkeleton = __decorate([
    Component({
        components: {},
    })
], TodoDetailsSkeleton);
export default TodoDetailsSkeleton;
