import { getPageNumber, processPageData, pageSize, isLastPage } from '../../Helpers/PageHelpers';
import { getAppointments, getAllAppointmentTypes } from '@/Webservice/Appointment';
import _ from 'lodash';
function createState() {
    return {
        appointments: [],
        appointmentTypes: [],
        isLastPage: false,
        isLoadingAppointmentData: false
    };
}
export const Appointments = {
    namespaced: true,
    state: createState(),
    mutations: {
        setAppointments(state, payload) {
            state.appointments = payload;
        },
        setAppointmentTypes(state, payload) {
            state.appointmentTypes = payload;
        },
        updateAppointment(state, payload) {
            const idx = _.findIndex(state.appointments, app => app.Id == payload.Id);
            if (idx >= 0) {
                state.appointments.splice(idx, 1, payload);
            }
            else {
                state.appointments.push(payload);
            }
        },
        setIsLastPage(state, payload) {
            state.isLastPage = payload;
        },
        setIsLoadingAppointmentData(state, payload) {
            state.isLoadingAppointmentData = payload;
        },
        resetState(state) {
            Object.assign(state, createState());
        }
    },
    actions: {
        async loadAppointmentTypes(store, payload) {
            setLoading(store, true);
            try {
                //Appointment types already loaded
                if (store.state.appointmentTypes.length > 0)
                    return;
                let page = 1;
                let lastPage = false;
                let appointmentTypes = [];
                while (!lastPage) {
                    const newAppointmentTypes = await getAllAppointmentTypes(page, pageSize);
                    lastPage = isLastPage(newAppointmentTypes);
                    page++;
                    appointmentTypes = processPageData(appointmentTypes, newAppointmentTypes);
                }
                store.commit("setAppointmentTypes", appointmentTypes);
            }
            finally {
                setLoading(store, false);
            }
        },
        async getMoreAppointments(store) {
            setLoading(store, true);
            try {
                const page = getPageNumber(store.state.appointments);
                const response = await getAppointments(store.rootState.employeeId, page, pageSize);
                const currentData = store.state.appointments;
                if (response) {
                    store.commit("setAppointments", processPageData(currentData, response));
                    store.commit("setIsLastPage", isLastPage(response));
                }
            }
            finally {
                setLoading(store, false);
            }
        },
    }
};
function setLoading(store, isLoading) {
    store.commit("setIsLoadingAppointmentData", isLoading);
}
