import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
let CRMToggleCard = class CRMToggleCard extends Vue {
    title;
    selectedValue;
    get isSelected() {
        return this.selectedValue;
    }
    setSelected() {
        this.$emit('click');
    }
};
__decorate([
    Prop({ type: String, required: true })
], CRMToggleCard.prototype, "title", void 0);
__decorate([
    PropSync('selected', { type: Boolean })
], CRMToggleCard.prototype, "selectedValue", void 0);
CRMToggleCard = __decorate([
    Component({
        components: {},
    })
], CRMToggleCard);
export default CRMToggleCard;
