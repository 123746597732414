import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import RelationMap from "@/components/Relations/RelationMap.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const RelationsNS = namespace("Relations/");
let MyRelationsMap = class MyRelationsMap extends Vue {
    myRelationLocations;
    loadMyRelationLocations;
    async mounted() {
        if (this.myRelationLocations == null || this.myRelationLocations.length == 0) {
            this.loadMyRelationLocations();
        }
    }
};
__decorate([
    RelationsNS.State
], MyRelationsMap.prototype, "myRelationLocations", void 0);
__decorate([
    RelationsNS.Action
], MyRelationsMap.prototype, "loadMyRelationLocations", void 0);
MyRelationsMap = __decorate([
    Component({
        components: {
            AppBar,
            RelationMap
        },
    })
], MyRelationsMap);
export default MyRelationsMap;
