import { __decorate } from "tslib";
import AppointmentDetailsParticipants from '@/components/Appointments/AppointmentDetailsParticipants.vue';
import ContactSelectionDialog from "@/components/Contacts/ContactSelectionDialog.vue";
import CRMDataField from "@/components/CRMDataField.vue";
import EmployeeSelectionDialog from "@/components/Employees/EmployeeSelectionDialog.vue";
import FormGroup from "@/components/FormGroup.vue";
import RelationPicker from "@/components/Relations/RelationPicker.vue";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
import { openGoogleMaps } from '@/Helpers/LinkHelpers';
import { trackAction } from '@/store/Helpers/UserMonitor';
import { Appointment } from "@/types/Api/appointment";
import _ from 'lodash';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { maxLength, required } from 'vuelidate/lib/validators';
import { namespace, State } from "vuex-class";
const AppointmentsNS = namespace("Appointments/");
let AppointmentEditForm = class AppointmentEditForm extends Vue {
    saving;
    appointment;
    isLoadingData;
    loadAppointmentTypes;
    appointmentTypes;
    showAddParticipantMenu = false;
    showEmployeeSelection = false;
    showContactSelection = false;
    participantIds = [];
    externalContactIds = [];
    otherParticipants() {
        this.setParticipantIds();
    }
    externalContacts() {
        this.setExternalContactIds();
    }
    async mounted() {
        this.setParticipantIds();
        this.setExternalContactIds();
        this.loadAppointmentTypes();
        if (this.appointment) {
            //Fix because time-zone handling in server is not working properly
            const start = this.appointment.StartTimeAsDate;
            this.appointment.StartTimeAsDate = start;
            const end = this.appointment.EndTimeAsDate;
            this.appointment.EndTimeAsDate = end;
        }
    }
    setParticipantIds() {
        this.participantIds = this.appointment?.OtherParticipants?.map(x => x.Employee?.Id ?? 0) ?? [];
    }
    setExternalContactIds() {
        this.externalContactIds = this.appointment?.ExternalParticipants?.map(x => x.Contact.Id) ?? [];
    }
    canDeleteParticipant(participant) {
        if (participant.externalParticipant)
            return true;
        if (participant.internalParticipant?.Employee?.Id != this.appointment.Employee?.Id)
            return true;
        return false;
    }
    showEmployeeSelect() {
        this.showEmployeeSelection = true;
    }
    showContactSelect() {
        this.showContactSelection = true;
    }
    onUpdateAppointmentType(selectedAppointmentType) {
        this.appointment.AppointmentType = selectedAppointmentType;
        this.$v.appointment.AppointmentType?.$touch();
    }
    onUpdateRelation(val) {
        if (val)
            this.appointment.LocationString = val.Address.RecordTag;
        else
            this.appointment.LocationString = "";
    }
    openGoogleMaps() {
        openGoogleMaps(this.appointment.LocationString);
    }
    get relationName() {
        return this.appointment.Relation?.RecordTag ?? "";
    }
    onEmployeeSelected(selectedEmployee) {
        if (!this.appointment.OtherParticipants)
            this.appointment.OtherParticipants = [];
        let exists = false;
        //Check if selected employee already exists
        this.appointment.OtherParticipants.forEach(element => {
            if (element.Employee?.Id == selectedEmployee.Id) {
                exists = true;
            }
        });
        if (exists) {
            console.warn('Duplicate employee detected.');
            return;
        }
        let newAppointment = new Appointment();
        newAppointment.Description = this.appointment.Description;
        newAppointment.OriginalStartTime = this.appointment.OriginalStartTime;
        newAppointment.OriginalEndTime = this.appointment.OriginalEndTime;
        newAppointment.StartTime = this.appointment.StartTime;
        newAppointment.EndTime = this.appointment.EndTime;
        newAppointment.AllDayEvent = this.appointment.AllDayEvent;
        newAppointment.Relation = this.appointment.Relation;
        newAppointment.LocationString = this.appointment.LocationString;
        newAppointment.Contact = this.appointment.Contact;
        newAppointment.Done = this.appointment.Done;
        newAppointment.AppointmentType = this.appointment.AppointmentType;
        newAppointment.Memo = this.appointment.Memo;
        newAppointment.Employee = selectedEmployee;
        trackAction("Add appointment employee");
        this.appointment.OtherParticipants.push(newAppointment);
    }
    onContactSelected(contact) {
        if (!this.appointment.ExternalParticipants)
            this.appointment.ExternalParticipants = [];
        let exists = false;
        this.appointment.ExternalParticipants.forEach(element => {
            if (element.Contact.Id == contact.Id) {
                exists = true;
            }
        });
        if (exists)
            return;
        let newParticipant = {
            Relation: contact.Relation,
            Contact: contact,
            MainParticipant: false
        };
        trackAction("Add appointment contact");
        this.appointment.ExternalParticipants.push(newParticipant);
    }
    deleteParticipant(participant) {
        if (participant.internalParticipant && this.appointment.OtherParticipants) {
            _.remove(this.appointment.OtherParticipants, p => p.Employee?.Id === participant.internalParticipant?.Employee?.Id);
            this.setParticipantIds();
        }
        else if (participant.externalParticipant && this.appointment.ExternalParticipants) {
            _.remove(this.appointment.ExternalParticipants, p => p.Contact.Id === participant.externalParticipant?.Contact.Id);
            this.setExternalContactIds();
        }
        //Hack.. reactivity doesn't seem to pick this up..
        this.$forceUpdate();
        trackAction("Delete appointment participant");
    }
    validate() {
        this.$v.$touch();
        return !this.$v.$invalid;
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], AppointmentEditForm.prototype, "saving", void 0);
__decorate([
    Prop({ type: Object, default: null })
], AppointmentEditForm.prototype, "appointment", void 0);
__decorate([
    State
], AppointmentEditForm.prototype, "isLoadingData", void 0);
__decorate([
    AppointmentsNS.Action
], AppointmentEditForm.prototype, "loadAppointmentTypes", void 0);
__decorate([
    AppointmentsNS.State
], AppointmentEditForm.prototype, "appointmentTypes", void 0);
__decorate([
    Watch("appointment.OtherParticipants")
], AppointmentEditForm.prototype, "otherParticipants", null);
__decorate([
    Watch("appointment.ExternalParticipants")
], AppointmentEditForm.prototype, "externalContacts", null);
__decorate([
    Watch("appointment.Relation")
], AppointmentEditForm.prototype, "onUpdateRelation", null);
AppointmentEditForm = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            AppointmentDetailsParticipants,
            RelationPicker,
            EmployeeSelectionDialog,
            ContactSelectionDialog,
            SkeletonEditField
        },
        validations: {
            appointment: {
                AppointmentType: {
                    required
                },
                Description: {
                    required,
                    maxLength: maxLength(80)
                },
                OriginalStartTimeAsDate: {
                    required
                },
                OriginalEndTimeAsDate: {
                    required,
                    appointmentEndDate: (value, appointment) => {
                        return value > appointment.OriginalStartTimeAsDate;
                    }
                }
            }
        }
    })
], AppointmentEditForm);
export default AppointmentEditForm;
