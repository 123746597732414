export class Relation {
    RecordTag;
    Address;
    SalesPersonId;
    WebsiteUrl;
    EmailAddress;
    PhoneNumber;
    PhoneNumber2;
    Contacts;
    Id;
    Name;
    constructor(dto) {
        this.RecordTag = dto.RecordTag;
        this.Address = dto.Address;
        this.SalesPersonId = dto.SalesPersonId;
        this.WebsiteUrl = dto.WebsiteUrl;
        this.EmailAddress = dto.EmailAddress;
        this.PhoneNumber = dto.PhoneNumber;
        this.PhoneNumber2 = dto.PhoneNumber2;
        this.Contacts = dto.Contacts;
        this.Id = dto.Id;
        this.Name = dto.Name;
    }
    _Appointments = [];
    get Appointments() {
        return this._Appointments;
    }
    set Appointments(value) {
        this._Appointments = value;
    }
    _Todos = [];
    get Todos() {
        return this._Todos;
    }
    set Todos(value) {
        this._Todos = value;
    }
    get AddressString() {
        if (this.Address)
            return this.Address.RecordTag;
        else
            return "-";
    }
    getDistanceFromLatLonInKm(lat1, lon1) {
        if (!this.Address?.Lat || !this.Address?.Lon)
            return -1;
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(this.Address.Lat - lat1); // deg2rad below
        const dLon = this.deg2rad(this.Address.Lon - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(this.Address.Lat)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    }
    deg2rad(deg) {
        return deg * (Math.PI / 180);
    }
}
