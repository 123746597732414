import { __decorate } from "tslib";
import { goBack } from "@/Helpers/NavigationHelpers";
import { getAppointmentDetails } from "@/Webservice/Appointment";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Appointments = namespace("Appointments/");
let AppointmentDetailsView = class AppointmentDetailsView extends Vue {
    selectedAppointment = null;
    async loadAppointment() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            goBack();
            return;
        }
        let appointment = await getAppointmentDetails(id);
        if (appointment) {
            this.selectedAppointment = appointment;
        }
        else {
            console.error("Appointment not found");
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadAppointment();
    }
    onAppointmentChanged(appointment) {
        this.selectedAppointment = appointment;
    }
};
__decorate([
    Watch('$route', { immediate: true, deep: true })
], AppointmentDetailsView.prototype, "onUrlChange", null);
AppointmentDetailsView = __decorate([
    Component({
        components: {},
    })
], AppointmentDetailsView);
export default AppointmentDetailsView;
