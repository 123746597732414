<template>
  <div asdad>
    <slot
      :attrs="{ errorMessages: activeErrorMessages }"
      :has-errors="hasErrors"
    />
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
export default {
  extends: singleErrorExtractorMixin
};
</script>