import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import MenuItem from "@/types/MenuItem";
import * as _ from 'lodash';
import AppBar from "@/components/AppBar.vue";
import { namespace } from "vuex-class";
const RelationsNS = namespace("Relations/");
const EmployeeNS = namespace("Employees/");
let Home = class Home extends Vue {
    loadAllPositions;
    getEmployees;
    get menuItems() {
        let retVar = [];
        if (this.$router.options.routes != null) {
            this.$router.options.routes.forEach(route => {
                let routeMeta = route.meta;
                if (routeMeta) {
                    if (routeMeta.showInMenu && routeMeta.requiresAuth == this.$auth.isAuthenticated) {
                        retVar.push(new MenuItem(route.name ?? "", routeMeta.menuIcon, route.path, routeMeta.menuOrder, routeMeta.menuTranslation));
                    }
                }
            });
            retVar = _.orderBy(retVar, ['order'], ['asc']);
        }
        return retVar;
    }
};
__decorate([
    RelationsNS.Action
], Home.prototype, "loadAllPositions", void 0);
__decorate([
    EmployeeNS.Action
], Home.prototype, "getEmployees", void 0);
Home = __decorate([
    Component({
        components: {
            AppBar,
        },
    })
], Home);
export default Home;
