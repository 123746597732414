import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import MenuItem from "@/types/MenuItem";
import * as _ from 'lodash';
import ErrorBar from "@/components/ErrorBar.vue";
import AdministrationSelectionDialog from "./components/AdministrationSelectionDialog.vue";
let App = class App extends Vue {
    drawer = false;
    showDrawer;
    setShowDrawer;
    userLanguage;
    get isLogoutRoute() {
        return this.$route.name == 'Logout';
    }
    onLanguageChange(newVal, oldVal) {
        this.$root.$i18n.locale = newVal;
    }
    mounted() {
        this.$root.$i18n.locale = this.userLanguage;
    }
    get shouldShowDrawer() {
        return (this.$route.name != 'Login' && this.$route.name != 'ChooseAdministration' &&
            (this.showDrawer || !this.$vuetify.breakpoint.mobile));
    }
    set shouldShowDrawer(show) {
        this.setShowDrawer(show);
    }
    get menuItems() {
        let retVar = [];
        if (this.$router.options.routes != null) {
            this.$router.options.routes.forEach(route => {
                let routeMeta = route.meta;
                if (routeMeta) {
                    if (routeMeta.showInMenu && routeMeta.requiresAuth == this.$auth.isAuthenticated) {
                        retVar.push(new MenuItem(route.name ?? "", routeMeta.menuIcon, route.path, routeMeta.menuOrder, routeMeta.menuTranslation));
                    }
                }
            });
            retVar = _.orderBy(retVar, ['order'], ['asc']);
        }
        return retVar;
    }
};
__decorate([
    State
], App.prototype, "showDrawer", void 0);
__decorate([
    Mutation
], App.prototype, "setShowDrawer", void 0);
__decorate([
    Getter
], App.prototype, "userLanguage", void 0);
__decorate([
    Watch('userLanguage')
], App.prototype, "onLanguageChange", null);
App = __decorate([
    Component({
        components: {
            ErrorBar,
            AdministrationSelectionDialog
        },
    })
], App);
export default App;
