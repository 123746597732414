import { getEmployees } from '@/Webservice/Employee';
import { isLastPage, pageSize, processPageData } from "@/store/Helpers/PageHelpers";
function createState() {
    return {
        employees: [],
        isLoading: false
    };
}
export const Employees = {
    namespaced: true,
    state: createState(),
    mutations: {
        setEmployees(state, payload) {
            state.employees = payload;
        },
        setIsLoading(state, payload) {
            state.isLoading = payload;
        },
        resetState(state) {
            Object.assign(state, createState());
        }
    },
    actions: {
        async getEmployees(store) {
            setLoading(store, true);
            try {
                let page = 1;
                let lastPage = false;
                let employees = [];
                while (!lastPage) {
                    const newEmployees = await getEmployees(page, pageSize);
                    lastPage = isLastPage(newEmployees);
                    page++;
                    employees = processPageData(employees, newEmployees);
                }
                store.commit("setEmployees", employees);
            }
            finally {
                setLoading(store, false);
            }
        }
    }
};
function setLoading(store, isLoading) {
    store.commit("setIsLoading", isLoading);
}
