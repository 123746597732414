import { __decorate } from "tslib";
import { Component, Vue, Emit, ModelSync, Prop, Watch } from "vue-property-decorator";
import _ from 'lodash';
import ContactListItemSkeleton from "./ContactListItemSkeleton.vue";
import { GetRelationContacts } from "@/Webservice/Relation";
import { searchContacts } from "@/Webservice/Contact";
import ContactListItem from "./ContactListItem.vue";
let ContactSelectionDialog = class ContactSelectionDialog extends Vue {
    showDialogValue;
    relation;
    externalContactIds;
    searchQuery = "";
    relationContacts = [];
    searchContacts = [];
    isLoadingRelationContacts = false;
    selectedContact;
    isLoadingContacts = false;
    contacts = [];
    debounceFn;
    async mounted() {
        this.selectedContact = undefined;
    }
    created() {
        this.debounceFn = _.debounce(this.loadOtherContacts, 500, { trailing: true });
    }
    async onVisibleChanged(val, oldVal) {
        if (val) {
            await this.loadRelationContacts();
        }
    }
    async onsearchChanged(val, oldVal) {
        if (this.debounceFn != null) {
            this.debounceFn.cancel();
            this.debounceFn();
        }
    }
    hide() {
        this.showDialogValue = false;
    }
    get searchResultsRelation() {
        const filteredRelationContacts = this.relationContacts.filter(con => !this.externalContactIds?.find(ext => (ext === con.Id)));
        if (this.searchQuery)
            return _.filter(filteredRelationContacts, rel => rel.RecordTag.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0 ||
                (rel.Relation ? (rel.Relation.Name ?? rel.Relation.RecordTag).toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0 : false));
        else
            return filteredRelationContacts;
    }
    get searchResultsContacts() {
        const filteredContacts = this.contacts.filter(con => !this.externalContactIds?.find(ext => (ext === con.Id)));
        if (this.relation)
            return _.filter(filteredContacts, contact => contact.Relation?.Id != this.relation.Id);
        else
            return filteredContacts;
    }
    selectContact(contact) {
        this.searchQuery = "";
        this.selectedContact = contact;
        this.showDialogValue = false;
        this.onContactSelected();
    }
    onContactSelected() {
        return this.selectedContact;
    }
    async loadRelationContacts() {
        if (this.isLoadingRelationContacts)
            return;
        if (!this.relation) {
            this.relationContacts = [];
            return;
        }
        this.isLoadingRelationContacts = true;
        try {
            let data = await GetRelationContacts(this.relation.Id);
            this.relationContacts = data;
        }
        finally {
            this.isLoadingRelationContacts = false;
        }
    }
    async loadOtherContacts() {
        if (this.isLoadingContacts)
            return;
        if (this.searchQuery == "") {
            this.contacts = [];
            return;
        }
        this.isLoadingContacts = true;
        try {
            let data = await searchContacts(this.searchQuery, 1, 20);
            this.contacts = data;
        }
        finally {
            this.isLoadingContacts = false;
        }
    }
};
__decorate([
    ModelSync('showDialog', 'visiblityChanged', { type: Boolean, required: true })
], ContactSelectionDialog.prototype, "showDialogValue", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ContactSelectionDialog.prototype, "relation", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], ContactSelectionDialog.prototype, "externalContactIds", void 0);
__decorate([
    Watch('showDialogValue')
], ContactSelectionDialog.prototype, "onVisibleChanged", null);
__decorate([
    Watch('searchQuery')
], ContactSelectionDialog.prototype, "onsearchChanged", null);
__decorate([
    Emit()
], ContactSelectionDialog.prototype, "onContactSelected", null);
ContactSelectionDialog = __decorate([
    Component({
        components: {
            ContactListItemSkeleton,
            ContactListItem
        },
    })
], ContactSelectionDialog);
export default ContactSelectionDialog;
