export class User {
    sub;
    names;
    nickname;
    picture;
    updatedAt;
    email;
    emailVerified;
    provider;
    id;
    givenName;
    familyName;
    locale;
    constructor(auth0User) {
        if (!auth0User)
            return;
        for (const key in auth0User) {
            this[key] = auth0User[key];
        }
        this.sub = auth0User.sub;
        this.provider = this.sub.split('|')[0];
        this.id = this.sub.split('|')[1];
    }
}
