import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
let ContactListItem = class ContactListItem extends Vue {
    contact;
    get subtitle() {
        let retVal = "";
        if (this.contact.PositionObj)
            retVal = this.contact.PositionObj.Description;
        if (this.contact.Relation && this.contact.PositionObj)
            retVal += " - ";
        if (this.contact.Relation)
            retVal += this.contact.Relation.Name ?? this.contact.Relation.RecordTag;
        return retVal;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], ContactListItem.prototype, "contact", void 0);
ContactListItem = __decorate([
    Component({
        components: {},
    })
], ContactListItem);
export default ContactListItem;
