import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import RelationMap from "@/components/Relations/RelationMap.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const RelationsNS = namespace("Relations/");
let AllRelationsMap = class AllRelationsMap extends Vue {
    allRelationLocations;
    loadAllRelationLocations;
    async mounted() {
        if (this.allRelationLocations == null || this.allRelationLocations.length == 0) {
            this.loadAllRelationLocations();
        }
    }
};
__decorate([
    RelationsNS.State
], AllRelationsMap.prototype, "allRelationLocations", void 0);
__decorate([
    RelationsNS.Action
], AllRelationsMap.prototype, "loadAllRelationLocations", void 0);
AllRelationsMap = __decorate([
    Component({
        components: {
            AppBar,
            RelationMap
        },
    })
], AllRelationsMap);
export default AllRelationsMap;
