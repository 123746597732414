import { __decorate } from "tslib";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import TodoList from "@/components/Todos/TodoList.vue";
import { GetRelationTodos } from "@/Webservice/Relation";
import { getPageNumber, isLastPage, pageSize, processPageData } from "@/store/Helpers/PageHelpers";
import { startOfToday } from "date-fns";
import _ from "lodash";
let RelationDetailTodos = class RelationDetailTodos extends Vue {
    relation;
    historyMode;
    isLoading = false;
    isLastPage = false;
    todosLocal = [];
    onRelationChanged() {
        this.todosLocal = [];
    }
    async onHistoryModeChanged() {
        await this.loadMoreTodos();
    }
    async mounted() {
        if (this.todosLocal.length == 0) {
            await this.loadMoreTodos();
        }
    }
    async loadMoreTodos() {
        try {
            this.isLoading = true;
            const page = getPageNumber(this.todosLocal);
            const relationTodos = await GetRelationTodos(this.relation.Id, this.historyMode, page, pageSize);
            this.isLastPage = isLastPage(relationTodos);
            this.todosLocal = processPageData(this.todosLocal, relationTodos);
        }
        finally {
            this.isLoading = false;
        }
    }
    get todos() {
        if (!this.historyMode)
            return _.filter(this.todosLocal, todo => !todo.ActionDate || new Date(todo.ActionDate) >= startOfToday());
        else
            return _.filter(this.todosLocal, todo => new Date(todo.ActionDate ?? "") < startOfToday());
    }
};
__decorate([
    Prop({ type: Object, default: null })
], RelationDetailTodos.prototype, "relation", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], RelationDetailTodos.prototype, "historyMode", void 0);
__decorate([
    Watch("relation")
], RelationDetailTodos.prototype, "onRelationChanged", null);
__decorate([
    Watch("historyMode")
], RelationDetailTodos.prototype, "onHistoryModeChanged", null);
RelationDetailTodos = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            TodoList
        },
    })
], RelationDetailTodos);
export default RelationDetailTodos;
