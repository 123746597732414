import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let AppointmentDetailsSkeleton = class AppointmentDetailsSkeleton extends Vue {
};
AppointmentDetailsSkeleton = __decorate([
    Component({
        components: {},
    })
], AppointmentDetailsSkeleton);
export default AppointmentDetailsSkeleton;
