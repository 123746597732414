import { __decorate } from "tslib";
import { goBack, openTodo } from "@/Helpers/NavigationHelpers";
import { Todo } from "@/types/Api/todo";
import { createTodo, getTodoDetails, saveTodo } from "@/Webservice/Todo";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TodoEditView from '@/components/Todos/TodoEditView.vue';
const Todos = namespace("Todos/");
let TodoEdit = class TodoEdit extends Vue {
    selectedTodo = null;
    saving = false;
    error = false;
    finished = false;
    appBarTitle = "";
    updateTodo;
    async loadTodo() {
        let idStr = this.$route.params['id'];
        let id = Number.parseInt(idStr);
        if (!id || id == 0) {
            this.appBarTitle = this.$t('new-todo').toString();
            //Create new todo
            this.selectedTodo = new Todo();
            return;
        }
        this.appBarTitle = this.$t('edit-todo').toString();
        let todo = await getTodoDetails(id);
        if (todo) {
            this.selectedTodo = todo;
        }
        else {
            console.error('Todo not found');
            goBack();
        }
    }
    onUrlChange(newVal) {
        this.loadTodo();
    }
    async onSaveTodo() {
        if (!this.selectedTodo)
            return;
        this.saving = true;
        let updatedTodo = undefined;
        let created = false;
        if (this.selectedTodo.Id > 0) {
            updatedTodo = await saveTodo(this.selectedTodo);
        }
        else {
            updatedTodo = await createTodo(this.selectedTodo);
            created = true;
        }
        if (updatedTodo?.Id > 0) {
            this.updateTodo(updatedTodo);
            this.finished = true;
            if (created)
                openTodo(updatedTodo.Id, true);
            else
                goBack();
        }
        else {
            this.error = true;
        }
    }
    setFinished() {
        this.saving = false;
        this.error = false;
        this.finished = false;
    }
};
__decorate([
    Todos.Action
], TodoEdit.prototype, "updateTodo", void 0);
__decorate([
    Watch('$route', { immediate: true, deep: true })
], TodoEdit.prototype, "onUrlChange", null);
TodoEdit = __decorate([
    Component({
        components: {
            TodoEditView,
        },
    })
], TodoEdit);
export default TodoEdit;
