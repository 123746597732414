import getUrl from "@/Helpers/BaseUrl";
import { Appointment } from "@/types/Api/appointment";
import { Todo } from "@/types/Api/todo";
import axios from "axios";
import _ from "lodash";
export async function saveTodo(todo) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Todo/${todo.Id}`), todo);
    return new Todo(response.data);
}
export async function createTodo(todo) {
    const response = await axios.post(getUrl(`/eci/v1/crmweb/Todo`), todo);
    return new Todo(response.data);
}
export async function getTodoDetails(todoId) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Todo/${todoId}`));
    return new Todo(response.data);
}
export async function getMyTodos(employeeId, page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Todo?employeeId=${employeeId}&page=${page}&size=${pageSize}`));
    return _.map(response.data, app => new Todo(app));
}
export async function getAllTodos(page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/Todo?employeeId=0&page=${page}&size=${pageSize}`));
    return _.map(response.data, app => new Todo(app));
}
export async function setTodoDone(todoId) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Todo/${todoId}/Done`), {});
    return new Todo(response.data);
}
export async function reOpenTodo(todoId) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Todo/${todoId}/Open`), {});
    return new Todo(response.data);
}
export async function setTodoWaitingForReply(todoId) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Todo/${todoId}/WaitingForReply`), {});
    return new Todo(response.data);
}
export async function getAllTodoTypes(page, pageSize) {
    const response = await axios.get(getUrl(`/eci/v1/crmweb/TodoType?page=${page}&size=${pageSize}`));
    return response.data;
}
export async function followUpTodoWithTodo(todoId, newTodo) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Todo/${todoId}/FollowUpTodo`), newTodo);
    return new Todo(response.data);
}
export async function followUpTodoWithAppointment(todoId, newAppointment) {
    const response = await axios.put(getUrl(`/eci/v1/crmweb/Todo/${todoId}/FollowUpAppointment`), newAppointment);
    return new Appointment(response.data);
}
