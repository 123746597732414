import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let RelationDetailsSkeleton = class RelationDetailsSkeleton extends Vue {
};
RelationDetailsSkeleton = __decorate([
    Component({
        components: {},
    })
], RelationDetailsSkeleton);
export default RelationDetailsSkeleton;
