import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let RelationListItemSkeleton = class RelationListItemSkeleton extends Vue {
};
RelationListItemSkeleton = __decorate([
    Component({
        components: {},
    })
], RelationListItemSkeleton);
export default RelationListItemSkeleton;
