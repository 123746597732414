import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
let AppointmentEditAppBar = class AppointmentEditAppBar extends Vue {
    loading;
    error;
    finished;
    appBarTitle;
    onSaveAppointment() {
        this.$emit('onSave');
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], AppointmentEditAppBar.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], AppointmentEditAppBar.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], AppointmentEditAppBar.prototype, "finished", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], AppointmentEditAppBar.prototype, "appBarTitle", void 0);
AppointmentEditAppBar = __decorate([
    Component({
        components: {
            AppBar,
            AppBarResponsiveButton
        },
    })
], AppointmentEditAppBar);
export default AppointmentEditAppBar;
