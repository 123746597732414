var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"offset-y":"","left":"","content-class":"ActionsMenuBackground"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2 UserMenu",attrs:{"data-test":"UserMenu"}},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#FFFFFF"}},[_vm._v(" user ")]),(_vm.isDesktop)?_c('span',{staticClass:"mr-2 UserName"},[_vm._v(_vm._s(_vm.userName))]):_vm._e(),(_vm.isDesktop)?_c('v-icon',{attrs:{"color":"#FFFFFF","small":""}},[_vm._v(" down7 ")]):_vm._e()],1)]}}],null,false,351711484)},[_c('v-list',{staticClass:"ActionsMenu py-0",attrs:{"dense":""}},[(_vm.isMobile)?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.userName)+" ")]):_vm._e(),(_vm.isMobile)?_c('v-divider',{staticClass:"mt-n2 mx-2"}):_vm._e(),_vm._l((_vm.administrations),function(item){return _c('v-list-item',{key:item.apiUserId,on:{"click":function($event){return _vm.setNewAdministration(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("inbox")])],1),_c('v-list-item-title',{class:{Active: _vm.isCurrentAdministration(item)}},[_vm._v(" "+_vm._s(item.administrationName)+" ")])],1)}),(_vm.administrations.length > 0)?_c('v-divider',{staticClass:"mx-2"}):_vm._e(),_c('v-list-item',[_c('v-list-item-title',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"LanguageFlag mr-2",class:{ActiveLanguage: _vm.isActiveLanguage('en'), LanguageFlagHover: hover},attrs:{"height":"40px","width":"50px","aspect-ratio":"4:3","contain":"","data-test":"LanguageEn","src":require("@/assets/images/en.svg")},on:{"click":function($event){return _vm.setLanguage('en')}}})]}}],null,false,828312797)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"LanguageFlag",class:{ActiveLanguage: _vm.isActiveLanguage('nl'), LanguageFlagHover: hover},attrs:{"height":"40px","width":"50px","aspect-ratio":"4:3","contain":"","data-test":"LanguageNL","src":require("@/assets/images/nl.svg")},on:{"click":function($event){return _vm.setLanguage('nl')}}})]}}],null,false,3841023709)})],1)],1),(_vm.isMobile)?_c('v-divider',{staticClass:"mx-2"}):_vm._e(),_c('v-list-item',{attrs:{"data-test":"Logout"},on:{"click":_vm.signout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("right3")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu_logout')))])],1),_c('v-list-item',{staticClass:"VersionInfo text-right"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.version))])],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }