import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import CustomErrorTemplate from '@/components/VuelidateErrorExtractor.vue';
export function addErrorExtractorPlugin(Vue) {
    Vue.use(vuelidateErrorExtractor, {
        /**
         * Optionally provide the template in the configuration.
         * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
         */
        //template: CustomErrorTemplate, // you can also pass your own custom template
        i18n: 'validation',
        attributes: { // maps form keys to actual field names
        }
    });
    // This will register the component globally
    Vue.component('FormWrapper', templates.FormWrapper);
    Vue.component("FormValidator", CustomErrorTemplate);
}
