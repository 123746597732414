import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
import CRMDataField from "@/components/CRMDataField.vue";
import FormGroup from "@/components/FormGroup.vue";
import { namespace } from "vuex-class";
import { required, maxLength, email } from 'vuelidate/lib/validators';
import ContactGenderSelector from "@/components/Contacts/ContactGenderSelector.vue";
const RelationsNS = namespace("Relations/");
let ContactEdit = class ContactEdit extends Vue {
    saving;
    contact;
    loadAllPositions;
    positions;
    isLoadingPositions;
    async mounted() {
        this.loadAllPositions();
    }
    onUpdatePosition(selectedPosition) {
        this.contact.PositionObj = selectedPosition;
    }
    validate() {
        this.$v.$touch();
        return !this.$v.$invalid;
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], ContactEdit.prototype, "saving", void 0);
__decorate([
    Prop({ type: Object, default: null })
], ContactEdit.prototype, "contact", void 0);
__decorate([
    RelationsNS.Action
], ContactEdit.prototype, "loadAllPositions", void 0);
__decorate([
    RelationsNS.State
], ContactEdit.prototype, "positions", void 0);
__decorate([
    RelationsNS.State
], ContactEdit.prototype, "isLoadingPositions", void 0);
ContactEdit = __decorate([
    Component({
        components: {
            CRMDataField,
            FormGroup,
            ContactGenderSelector
        },
        validations: {
            contact: {
                Person: {
                    LastName: {
                        required,
                        maxLength: maxLength(127)
                    },
                    FirstName: {
                        maxLength: maxLength(127)
                    },
                    Initials: {
                        maxLength: maxLength(50)
                    },
                    NamePrefix: {
                        maxLength: maxLength(50)
                    }
                },
                Position: {},
                CellPhone: {
                    maxLength: maxLength(50)
                },
                Gender: {},
                Email: {
                    maxLength: maxLength(255),
                    email
                },
                PhoneNumber1: {
                    maxLength: maxLength(50)
                },
                PhoneNumber2: {
                    maxLength: maxLength(50)
                }
            }
        }
    })
], ContactEdit);
export default ContactEdit;
