import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@babel/polyfill';
import interceptorsSetup from '@/Helpers/interceptors';
import addPlugins from './plugins';
import createi18n from './plugins/i18n';
import addTcComponents from './plugins/tccomponents';
import './styles/styles.scss';
import { datadogRum } from '@datadog/browser-rum';
datadogRum.init({
    applicationId: 'e66470d8-8105-42a3-865a-080a85cc2ed0',
    clientToken: 'pub214cac9347d7e93cb69646f1595ab680',
    site: 'datadoghq.com',
    service: 'crm-web',
    env: process.env.VUE_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: `V${process.env.VUE_APP_VERSION}`,
    sampleRate: 100,
    trackInteractions: false,
    trackViewsManually: true
});
interceptorsSetup();
addPlugins(Vue);
const { tcComponents } = addTcComponents(Vue);
Vue.config.productionTip = false;
new Vue({
    router,
    i18n: createi18n(),
    store,
    vuetify: tcComponents.vuetify,
    render: h => h(App)
}).$mount('#app');
