import CombinedParticipant from "../CombinedParticipant";
import { formatDateForJSON, parseDate, roundToNearest } from "@/Helpers/DateHelper";
import { subDays, addSeconds, differenceInSeconds, isSameDay } from "date-fns";
export class Appointment {
    Description;
    StartTime;
    EndTime;
    OriginalStartTime;
    OriginalEndTime;
    AllDayEvent;
    Relation;
    Location;
    get LocationString() {
        return this.Location;
    }
    set LocationString(value) {
        this.Location = value;
        this.LocationModified = true;
    }
    get OriginalStartTimeAsDate() {
        return parseDate(this.OriginalStartTime);
    }
    set OriginalStartTimeAsDate(value) {
        if (!value) {
            this.OriginalStartTime = "";
            this.OriginalEndTime = "";
            this.StartTime = "";
            this.EndTime = "";
            return;
        }
        const newStartTime = value;
        let durationInSeconds = 3600;
        if (this.OriginalEndTime)
            durationInSeconds = Math.abs(differenceInSeconds(this.OriginalStartTimeAsDate, this.OriginalEndTimeAsDate));
        this.OriginalStartTime = formatDateForJSON(newStartTime);
        this.OriginalEndTimeAsDate = addSeconds(newStartTime, durationInSeconds);
        this.OriginalStartTime = formatDateForJSON(value);
        //Copy to Start time field. This field is used for saving, sorting and grouping
        this.StartTime = this.OriginalStartTime;
    }
    get OriginalEndTimeAsDate() {
        return parseDate(this.OriginalEndTime);
    }
    set OriginalEndTimeAsDate(value) {
        if (!value) {
            this.OriginalEndTime = "";
            this.EndTime = "";
            return;
        }
        this.OriginalEndTime = formatDateForJSON(value);
        //Copy to End time field. This field is used for saving, sorting and grouping
        this.EndTime = this.OriginalEndTime;
    }
    get EndTimeAsDate() {
        return parseDate(this.EndTime);
    }
    set EndTimeAsDate(value) {
        if (!value) {
            this.EndTime = "";
            return;
        }
        this.EndTime = formatDateForJSON(value);
    }
    get StartTimeAsDate() {
        return parseDate(this.StartTime);
    }
    set StartTimeAsDate(value) {
        if (!value) {
            this.StartTime = "";
            return;
        }
        this.StartTime = formatDateForJSON(value);
    }
    Memo;
    Done;
    Employee;
    Contact;
    OtherParticipants;
    ExternalParticipants;
    Address;
    AppointmentType;
    CanSetDone;
    CanReOpen;
    CanFollowUp;
    LocationModified;
    DisableAppointmentEdit;
    Id;
    constructor(dto) {
        const currentDate = roundToNearest(new Date());
        const newEndTime = roundToNearest(new Date());
        newEndTime.setHours(newEndTime.getHours() + 1);
        this.Description = dto?.Description ?? '';
        this.OriginalStartTime = dto?.OriginalStartTime ?? formatDateForJSON(currentDate);
        if (dto?.OriginalEndTime) {
            this.OriginalEndTime = dto?.OriginalEndTime;
        }
        else {
            this.OriginalEndTime = formatDateForJSON(newEndTime);
        }
        if (dto) {
            this.StartTime = dto?.StartTime ?? this.OriginalStartTime;
            this.EndTime = dto?.EndTime ?? this.OriginalEndTime;
        }
        else {
            //Satify compiler...
            this.StartTime = this.OriginalStartTime;
            this.EndTime = this.OriginalEndTime;
        }
        this.AllDayEvent = dto?.AllDayEvent ?? false;
        this.Relation = dto?.Relation;
        this.Location = dto?.Location ?? '';
        this.Memo = dto?.Memo ?? '';
        this.Done = dto?.Done ?? false;
        this.Employee = dto?.Employee;
        this.Contact = dto?.Contact;
        this.OtherParticipants = dto?.OtherParticipants;
        this.ExternalParticipants = dto?.ExternalParticipants;
        this.Address = dto?.Address;
        this.AppointmentType = dto?.AppointmentType;
        this.CanSetDone = dto?.CanSetDone ?? false;
        this.CanReOpen = dto?.CanReOpen ?? false;
        this.CanFollowUp = dto?.CanFollowUp ?? false;
        this.LocationModified = dto?.LocationModified ?? false;
        this.DisableAppointmentEdit = dto?.DisableAppointmentEdit ?? false;
        this.Id = dto?.Id ?? 0;
        //this._combinedParticipants = CombinedParticipant.FromAppointment(this);
    }
    get CombinedParticipants() {
        return CombinedParticipant.FromAppointment(this);
    }
    get CorrectedOriginalEndDate() {
        if (this.AllDayEvent) {
            if (!isSameDay(this.OriginalStartTimeAsDate, this.OriginalEndTimeAsDate) && this.isTimeZero(this.OriginalEndTimeAsDate)) {
                return subDays(this.OriginalEndTimeAsDate, 1);
            }
        }
        return this.OriginalEndTimeAsDate;
    }
    isMultiDay() {
        // In case of a AllDayEvent we subtract 1 second so in the gui the correct information
        // is shown: 'All day event' instead of 'Multi day appointment'
        if (this.AllDayEvent)
            return !isSameDay(this.OriginalStartTimeAsDate, addSeconds(this.OriginalEndTimeAsDate, -1));
        return !isSameDay(this.OriginalStartTimeAsDate, this.OriginalEndTimeAsDate);
    }
    isTimeZero(date) {
        return (date.getHours() || date.getMinutes() || date.getSeconds() || date.getMilliseconds()) ? false : true;
    }
}
