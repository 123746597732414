import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import AppointmentActionMenu from "@/components/Appointments/AppointmentActionMenu.vue";
import AppointmentDetailsComponent from "@/components/Appointments/AppointmentDetailsComponent.vue";
import AppointmentDetailsSkeleton from "@/components/Appointments/AppointmentDetailsSkeleton.vue";
import { editAppointment } from "@/Helpers/NavigationHelpers";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Appointments = namespace("Appointments/");
let AppointmentDetailsView = class AppointmentDetailsView extends Vue {
    appointment;
    updateAppointment;
    showAppointmentMenu = false;
    editAppointment() {
        if (this.appointment) {
            editAppointment(this.appointment.Id);
        }
    }
    onAppointmentChanged(appointment) {
        this.updateAppointment(appointment);
        return appointment;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], AppointmentDetailsView.prototype, "appointment", void 0);
__decorate([
    Appointments.Mutation
], AppointmentDetailsView.prototype, "updateAppointment", void 0);
__decorate([
    Emit('onAppointmentChanged')
], AppointmentDetailsView.prototype, "onAppointmentChanged", null);
AppointmentDetailsView = __decorate([
    Component({
        components: {
            AppointmentDetailsComponent,
            AppBar,
            AppointmentDetailsSkeleton,
            AppointmentActionMenu,
            AppBarResponsiveButton
        },
    })
], AppointmentDetailsView);
export default AppointmentDetailsView;
