import { __decorate } from "tslib";
import { TodoState } from '@/types/Api/todo';
import { Component, Prop, Vue } from "vue-property-decorator";
let TodoAvatar = class TodoAvatar extends Vue {
    todo;
    isWaitingForResponse() {
        return this.todo.State == TodoState.WaitingForResponse;
    }
    isDone() {
        return this.todo.State == TodoState.Done;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TodoAvatar.prototype, "todo", void 0);
TodoAvatar = __decorate([
    Component({
        components: {},
    })
], TodoAvatar);
export default TodoAvatar;
