import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let ContactListItemSkeleton = class ContactListItemSkeleton extends Vue {
};
ContactListItemSkeleton = __decorate([
    Component({
        components: {},
    })
], ContactListItemSkeleton);
export default ContactListItemSkeleton;
