import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import TodoList from "@/components/Todos/TodoList.vue";
import AppBar from "@/components/AppBar.vue";
import { namespace } from "vuex-class";
const todos = namespace('Todos/');
let MyTodos = class MyTodos extends Vue {
    allTodos;
    getAllTodos;
    hasMoreAllTodos;
    isLoadingAllTodoData;
    async mounted() {
        if (this.allTodos.length == 0) {
            this.loadMoreTodos();
        }
    }
    async loadMoreTodos() {
        await this.getAllTodos();
    }
};
__decorate([
    todos.State
], MyTodos.prototype, "allTodos", void 0);
__decorate([
    todos.Action
], MyTodos.prototype, "getAllTodos", void 0);
__decorate([
    todos.State
], MyTodos.prototype, "hasMoreAllTodos", void 0);
__decorate([
    todos.State
], MyTodos.prototype, "isLoadingAllTodoData", void 0);
MyTodos = __decorate([
    Component({
        components: {
            TodoList,
            AppBar
        },
    })
], MyTodos);
export default MyTodos;
