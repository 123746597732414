import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import ActionMenuButton from "./ActionMenuButton.vue";
let ActionMenu = class ActionMenu extends Vue {
    title;
    loading;
    error;
    finished;
    icon;
    buttonClass;
    showActionMenu = false;
    get defaultTitle() {
        return this.title ?? this.$t('actions-menu-title').toString();
    }
};
__decorate([
    Prop({ type: String, required: false })
], ActionMenu.prototype, "title", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ActionMenu.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ActionMenu.prototype, "error", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ActionMenu.prototype, "finished", void 0);
__decorate([
    Prop({ type: String, required: false, default: "down7" })
], ActionMenu.prototype, "icon", void 0);
__decorate([
    Prop({ type: String, required: false, default: "ActionMenuButton" })
], ActionMenu.prototype, "buttonClass", void 0);
ActionMenu = __decorate([
    Component({
        components: {
            ActionMenuButton
        },
    })
], ActionMenu);
export default ActionMenu;
