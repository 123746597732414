import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mutation, State, Action } from "vuex-class";
let AdministrationSelectionDialog = class AdministrationSelectionDialog extends Vue {
    setApiUserId;
    administrations;
    isLoadingAdministrations;
    isLoadingEmployeeInfo;
    userId;
    apiuserid;
    fetchAdministrations;
    fetchEmployeeId;
    administration = null;
    async onVisibleChanged(val, oldVal) {
        if (val) {
            //await this.loadRelationContacts();
        }
    }
    async onAuthenticatedChanged(newVal, oldVal) {
        if (newVal && !oldVal) {
            await this.fetchAdministrations();
            await this.fetchEmployeeId();
        }
    }
    get isAuthenticated() {
        return this.$auth.isAuthenticated;
    }
    get shouldShowAdministrationDialog() {
        if (!this.isAuthenticated)
            return false;
        return !this.apiuserid;
    }
    set shouldShowAdministrationDialog(val) {
        return;
    }
    onAdministrationsChanged(newValue, oldValue) {
        if (this.administrations?.length == 1) {
            this.administration = this.administrations[0];
            this.setAdministration();
        }
    }
    async setAdministration() {
        await this.setApiUserId(this.administration?.apiUserId);
        await this.fetchEmployeeId();
    }
};
__decorate([
    Mutation
], AdministrationSelectionDialog.prototype, "setApiUserId", void 0);
__decorate([
    State
], AdministrationSelectionDialog.prototype, "administrations", void 0);
__decorate([
    State
], AdministrationSelectionDialog.prototype, "isLoadingAdministrations", void 0);
__decorate([
    State
], AdministrationSelectionDialog.prototype, "isLoadingEmployeeInfo", void 0);
__decorate([
    State
], AdministrationSelectionDialog.prototype, "userId", void 0);
__decorate([
    State
], AdministrationSelectionDialog.prototype, "apiuserid", void 0);
__decorate([
    Action
], AdministrationSelectionDialog.prototype, "fetchAdministrations", void 0);
__decorate([
    Action
], AdministrationSelectionDialog.prototype, "fetchEmployeeId", void 0);
__decorate([
    Watch('showDialogValue')
], AdministrationSelectionDialog.prototype, "onVisibleChanged", null);
__decorate([
    Watch('isAuthenticated')
], AdministrationSelectionDialog.prototype, "onAuthenticatedChanged", null);
__decorate([
    Watch('administrations')
], AdministrationSelectionDialog.prototype, "onAdministrationsChanged", null);
AdministrationSelectionDialog = __decorate([
    Component({
        components: {},
    })
], AdministrationSelectionDialog);
export default AdministrationSelectionDialog;
