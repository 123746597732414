import _ from 'lodash';
export const pageSize = Number(process.env.VUE_APP_PAGESIZE);
export function isLastPage(data) {
    return data.length % pageSize != 0 || data.length == 0;
}
export function getPageNumber(currentData) {
    if (currentData.length < pageSize)
        return 1;
    if (currentData.length % pageSize == 0) {
        //Load next page
        return (currentData.length / pageSize) + 1;
    }
    else {
        //reload last page
        return Math.ceil(currentData.length / pageSize);
    }
}
export function processPageData(currentData, newData) {
    if (!newData)
        return currentData;
    const pageNumber = getPageNumber(currentData);
    if (pageNumber == 1)
        return newData;
    if (currentData.length % pageSize == 0)
        return _.concat(currentData, newData);
    const itemsToRemove = currentData.length % pageSize;
    if (itemsToRemove == 0)
        return currentData;
    currentData.splice(currentData.length - itemsToRemove, itemsToRemove);
    return _.concat(currentData, newData);
}
